/* eslint-disable */
import { DropdownOptionProps } from "./types";
import dropdownStyle from "./index.module.scss";
import { useTranslation } from "react-i18next";

const GetOptionClassName = (props: DropdownOptionProps<any>) => {
	let str = dropdownStyle.option;
	if (props.selected === true) {
		str += " " + dropdownStyle.selected;
	}
	if (props.selectable) {
		str += " " + dropdownStyle.selectable;
	}
	if (props.isCategory === true) {
		str += " " + dropdownStyle.category;
	}
	return str;
};

const DropdownOption = (props: DropdownOptionProps<any>) => {
	const { t } = useTranslation();

	const arrowClicked = (evt: any) => {
		// console.log("arrowClicked");
		evt.stopPropagation();
		props.onToggle?.call(this, props.value);
	};

	const itemClicked = (evt: any) => {
		// console.log("itemClicked");
		evt.stopPropagation();
		if (props.selectable === false) {
			props.onToggle?.call(this, props.value);
		} else {
			props.onClick?.call(this, props.value);
		}
	};

	let className = GetOptionClassName(props);
	let title = <>{props.value.title}</>;
	let marginLeft = "0px";
	if (props.value.level && props.value.level > 0) {
		marginLeft = (props.value.level * 10).toString() + "px";
	}
	let indicatorArrow = <></>;
	if (props.isCategory === true) {
		if (props.isOpen === true) {
			//up arrow
			indicatorArrow = (
				<div className={dropdownStyle.toggleArrow}>
					<img
						src="assets/images/white_arrow_up.png"
						onClick={arrowClicked}
					/>
				</div>
			);
		} else if (props.isOpen === false) {
			//down arrow
			indicatorArrow = (
				<div className={dropdownStyle.toggleArrow}>
					<img
						src="assets/images/white_arrow_down.png"
						onClick={arrowClicked}
					/>
				</div>
			);
		}
	}

	return (
		<div
			className={className}
			style={{ marginLeft: marginLeft }}
			onClick={itemClicked}
		>
			{t(title?.props?.children)}
			{indicatorArrow}
		</div>
	);
};

export default DropdownOption;
