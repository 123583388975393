import {Performance} from "../../generated/types";

const GetPerformanceClassName = (
    performance?: Performance,
    className?: string
  ) => {
    switch (performance) {
      case Performance.O:
        return `rounded-full bg-blue-500 ${className}`;
      case Performance.E:
        return `border-4 rounded-full border-blue-500 ${className}`;
      case Performance.S:
        return `rounded-full bg-yellow-300 ${className}`;
      case Performance.I:
        return `border-4 rounded-full border-burning-orange-500 ${className}`;
      case Performance.U:
      default:
        return `rounded-full bg-burning-orange-500 ${className}`;
    }
};

export {GetPerformanceClassName};