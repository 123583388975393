/* eslint-disable */

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Cut } from "../../generated/types";
import { GetIdsFromJson } from "./GetMapJson";
import Dropdown from "./Dropdown";

type CutOptionsProps = {
	selectedOptions: any;
	onChange?: (id: string, type: string) => void;
	onClear?: (type: string) => void;
};

const CutOptions = (props: CutOptionsProps) => {
	const [cities, setCities] = useState([]);
	const [zipCodes, setZipCodes] = useState([]);
	const [censusTracts, setCensusTracts] = useState([]);
	const [loading, setLoading] = useState(false);
	const { t } = useTranslation();

	const updateCensus = async () => {
		const data = await GetIdsFromJson(Cut.CensusTract);
		const updatedData = data.filter(
			(tract) => tract !== "6059980000" && tract !== "6059021813"
		);
		setCensusTracts(GetIdsForDropdown(updatedData));
	};

	useEffect(() => {
		setLoading(true);
		let data = GetIdsFromJson(Cut.City);
		setCities(GetIdsForDropdown(data));
		data = GetIdsFromJson(Cut.ZipCode);
		setZipCodes(GetIdsForDropdown(data));
		data = GetIdsFromJson(Cut.CensusTract);
		setCensusTracts(GetIdsForDropdown(data));
		setLoading(false);

		updateCensus();
	}, []);

	const GetIdsForDropdown = (data) => {
		let ddIds = [];
		for (let a = 0; a < data.length; a++) {
			ddIds.push({ id: data[a], title: data[a] });
		}
		ddIds = ddIds.sort((a: any, b: any) => {
			return (a.title as string).localeCompare(b.title as string);
		});
		return ddIds;
	};

	const onClear = (type) => {
		if (props.onClear) {
			props.onClear(type);
		}
	};

	const optionChanged = (value, type) => {
		if (props.onChange) {
			props.onChange(value.id, type);
		}
	};

	const selectedData = props.selectedOptions;
	const options = [
		{
			id: "",
			title: selectedData.city ? selectedData.city : "",
			options: cities,
			type: Cut.City,
		},
		{
			id: "",
			title: selectedData.zip ? selectedData.zip : "",
			options: zipCodes,
			type: Cut.ZipCode,
		},
		{
			id: "",
			title: selectedData.census_tract ? selectedData.census_tract : "",
			options: censusTracts,
			type: Cut.CensusTract,
		},
	];

	const selectInputs = options.map((opt) => {
		opt.id = opt.title;
		let autocomplete = true;
		let filteredOptions = opt.options.filter(
			(opt) => opt.id !== "92676" && opt.id !== "92678"
		);
		filteredOptions = [{ id: "ALL", title: "ALL" }, ...filteredOptions];

		return (
			<div key={opt.type} className="cut-option">
				<div className="select-title">{t(opt.type.toLowerCase())}</div>
				<div>
					<Dropdown
						options={filteredOptions.map((op) => {
							return op;
						})}
						value={opt.title == "" ? null : opt}
						onChange={(value) => {
							optionChanged(value, opt.type);
						}}
						onClear={() => onClear(opt.type)}
						placeholder={t("select_one")}
						autocomplete={autocomplete}
					></Dropdown>
				</div>
			</div>
		);
	});

	return <>{selectInputs}</>;
};
export default CutOptions;
