/* eslint-disable */

import { useTranslation } from "react-i18next";

const GetCDCCategories = () => {
	return [
		{
			id: "health_outcomes",
			layer_type: "cdc",
			title: "Health Outcomes",
			title_key: "health_outcomes",
			selectable: false,
			is_category: true,
			is_open: true,
		},
		{
			id: "unhealthy_behaviors",
			layer_type: "cdc",
			title: "Unhealthy Behaviors",
			title_key: "unhealthy_behaviors",
			selectable: false,
			is_category: true,
			is_open: false,
		},
		{
			id: "prevention",
			layer_type: "cdc",
			title: "Prevention",
			title_key: "prevention",
			selectable: false,
			is_category: true,
			is_open: false,
		},
	];
};

const GetDemographicsCategories = () => {
	return [
		{
			id: "general",
			layer_type: "demographics",
			title: "General",
			title_key: "general",
			selectable: false,
			is_category: true,
			is_open: true,
		},
		{
			id: "ethnicity",
			layer_type: "demographics",
			title: "Ethnicity",
			title_key: "ethnicity",
			selectable: false,
			is_category: true,
			is_open: false,
		},
		{
			id: "age_group",
			layer_type: "demographics",
			title: "Age Group",
			title_key: "age_group",
			selectable: false,
			is_category: true,
			is_open: false,
		},
	];
};

const GetBaseLayerCategories = (type: string) => {
	if (type == "cdc") {
		return GetCDCCategories();
	} else if (type == "demographics") {
		return GetDemographicsCategories();
	}
	return [];
};

const GetBaseLayerSets = () => {
	return [
		{
			type: "spi",
			title_key: "spi",
			title: "SPI",
			selected: true,
		},
		{
			type: "cdc",
			title_key: "health",
			title: "Health",
			selected: false,
		},
		{
			type: "demographics",
			title_key: "demographics",
			title: "Demographics",
			selected: false,
		},
	];
};

const SpiPercentageKeys = [
	"hw_cancer",
	"hw_obesity",
	"abk_g8math",
	"abk_hsincomplete",
	"abk_under5_comskills",
	"abk_under5_socioemotional",
	"abk_g3reading",
	"aic_celldata",
	"aic_broadband",
	"nbm_vax",
	"nbm_dental",
	"nbm_foodstamps",
	"nbm_preventative",
	"nbm_supermarketaccess",
	"s_overcrowded",
	"s_rapi",
	"s_hburdenrenter",
	"pr_registration",
	"pr_homeownership",
	"aae_graduate",
	"aae_bachelors",
	"aae_associates",
	"i_linguistic",
];

const GetBaseLayerContentValue = (layer: any, value: number) => {
	if (
		layer.layer_type == "spi" &&
		SpiPercentageKeys.indexOf(layer.content_key) != -1
	) {
		return value * 100;
	}
	return value;
};

const ShuffleDemographicsLayerForDropdown = (
	layers: any[],
	sortedLayers: any[]
) => {
	let layerType = "demographics";
	let cats = GetBaseLayerCategories(layerType);
	for (let a = 0; a < cats.length; a++) {
		let cat = cats[a];
		sortedLayers.push(cat);
		for (let b = 0; b < layers.length; b++) {
			let l = layers[b];
			if (l.layer_type == layerType && l.parent == cat.id) {
				sortedLayers.push(l);
			}
		}
	}
	return sortedLayers;
};

const ShuffleCDCLayerForDropdown = (layers: any[], sortedLayers: any[]) => {
	let layerType = "cdc";
	let cats = GetBaseLayerCategories(layerType);
	for (let a = 0; a < cats.length; a++) {
		let cat = cats[a];
		sortedLayers.push(cat);
		for (let b = 0; b < layers.length; b++) {
			let l = layers[b];
			if (l.layer_type == layerType && l.parent == cat.id) {
				sortedLayers.push(l);
			}
		}
	}
	return sortedLayers;
};

const HideSpiLevelRecursiveForDropdown = (
	options: any[],
	opt: any,
	level: number
) => {
	if (level == 4) {
		return options;
	}
	for (let a = 0; a < options.length; a++) {
		let optA = options[a];
		if (optA.parent == opt.content_key && optA.level == level) {
			options.splice(a, 1);
			a--;
			options = HideSpiLevelRecursiveForDropdown(
				options,
				optA,
				level + 1
			);
		}
	}
	return options;
};

const HideSpiLevelForDropDown = (options: any[]) => {
	for (let a = 0; a < options.length; a++) {
		let opt = options[a];
		if (opt.is_category === true && opt.is_open === false) {
			if (opt.level == 0) {
				for (let b = a + 1; b < options.length; b++) {
					let optB = options[b];
					if (optB.level > 0) {
						options.splice(b, 1);
						b--;
					}
				}
			} else if (opt.level > 0) {
				let nextLevel = opt.level + 1;
				options = HideSpiLevelRecursiveForDropdown(
					options,
					opt,
					nextLevel
				);
			}
		}
	}
	return options;
};

const ShuffleSpiLevelForDropdown = (
	layers: any[],
	sortedLayers: any[],
	parentLayer: any
) => {
	let parentLevel = parentLayer.level;
	if (parentLevel == 3) {
		return sortedLayers;
	}
	let layerType = "spi";
	let parentKey = parentLayer.content_key;
	let currentLevel = parentLevel + 1;
	for (let a = 0; a < layers.length; a++) {
		let l = layers[a];
		if (l.layer_type == layerType && l.parent == parentKey) {
			l.level = currentLevel;
			if (currentLevel == 1 || currentLevel == 2) {
				l.is_category = true;
				l.selectable = true;
				l.is_open = false;
			}
			sortedLayers.push(l);
			sortedLayers = ShuffleSpiLevelForDropdown(layers, sortedLayers, l);
		}
	}
	return sortedLayers;
};

const ShuffleSpiLayerForDropdown = (layers: any[], sortedLayers: any[]) => {
	let layerType = "spi";
	let mhiL = layers.find((val) => {
		return val.layer_type == layerType && val.content_key == "mhi";
	});
	let spiL = layers.find((val) => {
		return val.layer_type == layerType && val.content_key == "spi";
	});
	if (mhiL) {
		mhiL.level = 0;
		sortedLayers.push(mhiL);
	}
	if (spiL) {
		spiL.level = 0;
		spiL.is_category = true;
		spiL.selectable = true;
		spiL.is_open = false;
		sortedLayers.push(spiL);
		ShuffleSpiLevelForDropdown(layers, sortedLayers, spiL);
	}
	return sortedLayers;
};

const ShuffleBaseLayersForDropdown = (layers: any[], shuffledLayers: any[]) => {
	const layerSets = GetBaseLayerSets();
	for (let a = 0; a < layerSets.length; a++) {
		let layerType = layerSets[a].type;
		if (layerType == "spi") {
			ShuffleSpiLayerForDropdown(layers, shuffledLayers);
		} else if (layerType == "cdc") {
			ShuffleCDCLayerForDropdown(layers, shuffledLayers);
		} else if (layerType == "demographics") {
			ShuffleDemographicsLayerForDropdown(layers, shuffledLayers);
		}
	}
	return shuffledLayers;
};

const GetDefaultContentKeyForSet = (setType: any) => {
	let type = setType.type;
	if (type == "spi") return "spi";
	if (type == "cdc") return "stroke_crudeprev";
	if (type == "demographics") return "totpop";
	return "";
};

const GetDefaultBaseLayerForSet = (layers: any[], setType: any) => {
	const defaultLayer = [];
	if (setType) {
		const defaultKey = GetDefaultContentKeyForSet(setType);
		const bl = layers.find((val, idx, arr) => {
			return (
				val.layer_type == setType.type && val.content_key == defaultKey
			);
		});
		if (bl) {
			defaultLayer.push(bl);
		}
	}
	return defaultLayer;
};

const GetSpiQuartileOptions = () => {
	const { t } = useTranslation();
	const quartileOptions = [];
	//quartileOptions.push({id: "all",title: "All"});
	quartileOptions.push({ id: "top", title: t("top"), title_key: "top" });
	quartileOptions.push({
		id: "second_top",
		title: t("second_top"),
		title_key: "second_top",
	});
	quartileOptions.push({
		id: "second_bottom",
		title: t("second_bottom"),
		title_key: "second_bottom",
	});
	quartileOptions.push({
		id: "bottom",
		title: t("bottom"),
		title_key: "bottom",
	});
	return quartileOptions;
};

export {
	GetBaseLayerSets,
	GetBaseLayerCategories,
	GetBaseLayerContentValue,
	ShuffleBaseLayersForDropdown,
	GetDefaultBaseLayerForSet,
	GetSpiQuartileOptions,
	HideSpiLevelForDropDown,
};
