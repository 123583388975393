import React from "react";

import { ContainerProps } from "../lib/types";

type PaperProps = ContainerProps & {
  readonly className?: string;
};

const Paper = ({ children, className }: PaperProps): JSX.Element => (
  <div className={`${className}`} style={{position: "relative"}}>{children}</div>
);

export default Paper;
