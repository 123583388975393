import { useTranslation } from "react-i18next";
import IndicatorBar from "./IndicatorBar";
import { GetPerformanceClassName } from "./PerformanceClass";

const StatHeaderInfoTable = ({ pointData, isMain }) => {
	const { t } = useTranslation();
	return (
		<table style={{ width: "100%", marginTop: "5px" }}>
			<tbody>
				<tr className="stat-table-row">
					<td style={{ width: "70px", textAlign: "center" }}>
						<div style={{ width: "55%", margin: "auto" }}>
							<table
								style={{
									width: "50px",
									borderWidth: "0px",
								}}
							>
								<tr
									style={{
										display: "flex",
										alignItems: "center",
									}}
								>
									<td>
										<img
											src="assets/images/spi_marker_half.png"
											style={{
												width: "10px",
												height: "10px",
											}}
											alt=""
										/>
									</td>
									<td>
										<b>{t("spi")}</b>
									</td>
								</tr>
							</table>
						</div>
						<div style={{ textAlign: "center" }}>
							<span className="text-rameez-orange">
								<strong>
									{Number(
										isMain
											? pointData.data.valueRank
												? pointData.data.valueRank.value.toFixed(
														0
												  )
												: ""
											: pointData.data.value.toFixed(0)
									)}
								</strong>
							</span>
						</div>
					</td>
					<td style={{ width: "70px", textAlign: "center" }}>
						<div style={{ width: "70%", margin: "auto" }}>
							<table style={{ borderWidth: "0px" }}>
								<tr>
									<td>
										<img
											src="assets/images/rank_marker_half.png"
											style={{
												height: "10px",
												display: "inline",
												marginTop: "-1px",
											}}
											alt=""
										/>
									</td>
									<td>
										<b>{t("rank")}</b>
									</td>
								</tr>
							</table>
						</div>
						<div style={{ textAlign: "center" }}>
							<span className="text-rameez-orange">
								<strong>
									{Number(
										isMain
											? pointData.data.valueRank
												? pointData.data.valueRank.rank.toFixed(
														0
												  )
												: ""
											: pointData.data.rank.toFixed(0)
									)}
								</strong>
							</span>
						</div>
					</td>
					<td style={{ width: "100px" }}>
						<div
							style={{ margin: "auto" }}
							className={GetPerformanceClassName(
								isMain
									? pointData.data.valueRank?.performance
									: pointData.data.performance,
								"w-6 h-6"
							)}
						/>
					</td>
					<td
						style={{
							width: "80px",
							lineHeight: "1.2",
							textAlign: "center",
						}}
					>
						{/* Compared<br />to 2020: */}
						{t("compared_to")}
						<br />
						{t("last_year")}
					</td>
					<td style={{ width: "25px", textAlign: "center" }}>
						<img
							src="assets/images/arrow_top_right.png"
							style={{ width: "15px", display: "inline" }}
							alt=""
						/>
					</td>
				</tr>
			</tbody>
		</table>
	);
};

const StatInfoTable = ({ pointData, isMain }) => {
	const { t } = useTranslation();
	return (
		<table style={{ width: "100%", marginTop: "5px" }}>
			<tbody>
				<tr className="stat-table-row">
					<td style={{ width: "70px", textAlign: "center" }}>
						<div style={{ display: "inline-block" }}>
							<b>{t("spi").toUpperCase()}</b>
						</div>
						<br />
						<span className="text-rameez-orange">
							<strong>
								{Number(
									isMain
										? pointData.data.valueRank
											? pointData.data.valueRank.value.toFixed(
													0
											  )
											: ""
										: pointData.data.value.toFixed(0)
								)}
							</strong>
						</span>
					</td>
					<td style={{ width: "70px", textAlign: "center" }}>
						<div style={{ display: "inline-block" }}>
							<b>{t("rank")}</b>
						</div>
						<br />
						<span className="text-rameez-orange">
							<strong>
								{Number(
									isMain
										? pointData.data.valueRank
											? pointData.data.valueRank.rank.toFixed(
													0
											  )
											: ""
										: pointData.data.rank.toFixed(0)
								)}
							</strong>
						</span>
					</td>
					<td style={{ width: "100px" }}>
						<div
							style={{ margin: "auto" }}
							className={GetPerformanceClassName(
								isMain
									? pointData.data.valueRank?.performance
									: pointData.data.performance,
								"w-6 h-6"
							)}
						/>
					</td>
					<td
						style={{
							width: "80px",
							lineHeight: "1.2",
							textAlign: "center",
						}}
					>
						{/* Compared<br />to 2020: */}
						{t("compared_to")}
						<br />
						{t("last_year")}
					</td>
					<td style={{ width: "25px", textAlign: "center" }}>
						<img
							src="assets/images/arrow_top_right.png"
							style={{ width: "15px", display: "inline" }}
							alt=""
						/>
					</td>
				</tr>
			</tbody>
		</table>
	);
};

const StatTitleTable = ({ pointData, isMain }) => {
	return (
		<table style={{ width: "100%", marginTop: "10px" }}>
			<tbody>
				<tr>
					<td style={{ fontSize: "0.90rem" }}>
						{isMain ? <b>{pointData.title}</b> : pointData.title}
					</td>
				</tr>
			</tbody>
		</table>
	);
};

const StatTableMainRow = ({ pointData, isMain }) => {
	return (
		<div>
			<div style={{ paddingLeft: isMain ? "10px" : "20px" }}>
				<StatTitleTable
					pointData={pointData}
					isMain={isMain}
				></StatTitleTable>
			</div>
			<div style={{ paddingLeft: "15px", paddingRight: "15px" }}>
				<StatInfoTable
					pointData={pointData}
					isMain={isMain}
				></StatInfoTable>
			</div>
			<div style={{ marginTop: "10px" }}>
				<hr style={{ width: "100%" }} />
			</div>
		</div>
	);
};

const PrintableScoreCardFrontStat = ({ tableData, totalAreas }) => {
	let rows = [];
	if (tableData.selected === true) {
		for (let a = 0; a < tableData.points.length; a++) {
			let pt = tableData.points[a];
			let itemKey = "statTableRow_" + a.toString();
			rows.push(
				<StatTableMainRow
					key={itemKey}
					pointData={pt}
					isMain={true}
				></StatTableMainRow>
			);
		}
	}
	let spi = tableData.data.valueRank ? tableData.data.valueRank.value : 1;
	let rank = tableData.data.valueRank ? tableData.data.valueRank.rank : 1;
	return (
		<div className="print-sc-stat-table print-sc-stat-bordered">
			<div style={{ backgroundColor: "#eaeaea" }}>
				<div className="table-title">{tableData.title}</div>
				<div style={{ paddingLeft: "15px", paddingRight: "15px" }}>
					<StatHeaderInfoTable
						pointData={tableData}
						isMain={true}
					></StatHeaderInfoTable>
					<div style={{ marginTop: "10px" }}>
						<IndicatorBar
							width="100%"
							spi={spi}
							rank={rank}
							totalAreas={totalAreas}
						></IndicatorBar>
					</div>
				</div>
				<div className="separator"></div>
			</div>
			{rows}
		</div>
	);
};

export { PrintableScoreCardFrontStat };
