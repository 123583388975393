import { useEffect, useRef } from "react";

const OutsideAlerter = ({ children, onClose }) => {
	const wrapperRef = useRef(null);

	useEffect(() => {
		function handleClickOutside(event) {
			if (
				wrapperRef.current &&
				!wrapperRef.current.contains(event.target)
			) {
				onClose();
			}
		}

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [onClose]);

	return <div ref={wrapperRef}>{children}</div>;
};

export default OutsideAlerter;
