import axios from "../axios";

const getScorecard = async (year, geo, geoId) => {
	try {
		const response = axios.get(
			`/baselayers/scorecard/${year}/${geo}/${geoId}`
		);
		return response;
	} catch (err) {
		throw new Error(err?.response?.data || err.message);
	}
};

export default getScorecard;
