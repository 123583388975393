import { useTranslation } from "react-i18next";
import { GetPerformanceClassName } from "./PerformanceClass";

const StatHeaderInfoTable = ({ pointData, isMain }) => {
	const { t } = useTranslation();
	return (
		<table style={{ width: "100%", marginTop: "5px" }}>
			<tbody>
				<tr>
					<td
						style={{
							width: "70px",
							textAlign: "center",
							fontSize: "0.90rem",
						}}
					>
						<strong>SPI</strong>
						<br />
						<span className="text-rameez-orange">
							<strong>
								{isMain
									? pointData.data.valueRank &&
									  pointData.data.valueRank.value !== null
										? Number(
												pointData.data.valueRank.value
										  ).toFixed(0)
										: "N/A"
									: pointData.data.value !== null
									? Number(pointData.data.value).toFixed(0)
									: "N/A"}
							</strong>
						</span>
					</td>
					<td
						style={{
							width: "70px",
							textAlign: "center",
							fontSize: "0.90rem",
						}}
					>
						<strong>{t("rank")}</strong>
						<br />
						<span className="text-rameez-orange">
							<strong>
								{isMain
									? pointData.data.valueRank &&
									  pointData.data.valueRank.rank !== null
										? Number(
												pointData.data.valueRank.rank
										  ).toFixed(0)
										: "N/A"
									: pointData.data.rank !== null
									? Number(pointData.data.rank).toFixed(0)
									: "N/A"}
							</strong>
						</span>
					</td>
					<td style={{ width: "100px" }}>
						<div
							style={{ margin: "auto" }}
							className={GetPerformanceClassName(
								isMain
									? pointData.data.valueRank?.performance
									: pointData.data.performance,
								"w-6 h-6"
							)}
						/>
					</td>
					<td
						style={{
							width: "60px",
							fontSize: "0.70rem",
							lineHeight: "1.2",
							textAlign: "center",
						}}
					>
						Compared
						<br />
						to 2020:
					</td>
				</tr>
			</tbody>
		</table>
	);
};

const StatInfoTable = ({ pointData, isMain }) => {
	const { t } = useTranslation();
	return (
		<table style={{ width: "100%", marginTop: "5px" }}>
			<tbody>
				<tr>
					<td
						style={{
							width: "70px",
							textAlign: "center",
							fontSize: "0.90rem",
						}}
					>
						<strong>SPI</strong>
						<br />
						<span className="text-rameez-orange">
							<strong>
								{isMain
									? pointData.data.valueRank &&
									  pointData.data.valueRank.value !== null
										? Number(
												pointData.data.valueRank.value
										  ).toFixed(0)
										: "N/A"
									: pointData.data.value !== null
									? Number(pointData.data.value).toFixed(0)
									: "N/A"}
							</strong>
						</span>
					</td>
					<td
						style={{
							width: "70px",
							textAlign: "center",
							fontSize: "0.90rem",
						}}
					>
						<strong>{t("rank")}</strong>
						<br />
						<span className="text-rameez-orange">
							<strong>
								{isMain
									? pointData.data.valueRank &&
									  pointData.data.valueRank.rank !== null
										? Number(
												pointData.data.valueRank.rank
										  ).toFixed(0)
										: "N/A"
									: pointData.data.rank !== null
									? Number(pointData.data.rank).toFixed(0)
									: "N/A"}
							</strong>
						</span>
					</td>
					<td style={{ width: "100px" }}>
						<div
							style={{ margin: "auto" }}
							className={GetPerformanceClassName(
								isMain
									? pointData.data.valueRank?.performance
									: pointData.data.performance,
								"w-6 h-6"
							)}
						/>
					</td>
					<td
						style={{
							width: "60px",
							fontSize: "0.70rem",
							lineHeight: "1.2",
							textAlign: "center",
						}}
					>
						Compared
						<br />
						to 2020:
					</td>
				</tr>
			</tbody>
		</table>
	);
};

const StatTitleTable = ({ pointData, isMain }) => {
	return (
		<table style={{ width: "100%", marginTop: "10px" }}>
			<tbody>
				<tr>
					<td>
						{isMain ? <b>{pointData.title}</b> : pointData.title}
					</td>
				</tr>
			</tbody>
		</table>
	);
};

const StatTableMainRow = ({ pointData, isMain }) => {
	return (
		<tr className="back-stat-table-row">
			<td style={{ paddingLeft: "10px" }}>
				{isMain ? <b>{pointData.title}</b> : pointData.title}
			</td>
			<td style={{ textAlign: "center" }}>
				{isMain
					? pointData.data.valueRank &&
					  pointData.data.valueRank.value !== null
						? Number(pointData.data.valueRank.value).toFixed(0)
						: "N/A"
					: pointData.data.value !== null
					? Number(pointData.data.value).toFixed(0)
					: "N/A"}
			</td>
			<td style={{ textAlign: "center" }}>
				{isMain
					? pointData.data.valueRank &&
					  pointData.data.valueRank.rank !== null
						? Number(pointData.data.valueRank.rank).toFixed(0)
						: "N/A"
					: pointData.data.rank !== null
					? Number(pointData.data.rank).toFixed(0)
					: "N/A"}
			</td>
			<td>
				<div
					style={{ margin: "auto" }}
					className={GetPerformanceClassName(
						isMain
							? pointData.data.valueRank?.performance
							: pointData.data.performance,
						"w-3 h-3"
					)}
				/>
			</td>
		</tr>
	);
};

const PrintableScoreCardBackStat = ({
	tableData,
	scorecard,
	displaySimilar,
	onClickSimilar,
}) => {
	const { t } = useTranslation();
	let similar = <></>;
	if (displaySimilar === true) {
		if (scorecard.similarLabels && scorecard.similarLabels.length > 0) {
			let similarLabels = [];
			for (let a = 0; a < scorecard.similarLabels.length; a++) {
				similarLabels.push(
					<div
						className="printable-sc-similar-label"
						key={a}
						onClick={() => {
							onClickSimilar(scorecard.similarLabels[a]);
						}}
					>
						{scorecard.similarLabels[a]}
					</div>
				);
			}
			similar = (
				<div className="printable-sc-similar-labels">
					<div className="printable-sc-similar-label-title">
						Strengths and weaknesses of this tract are relative to
						10 census tracts of similar median household income:
					</div>
					<div
						style={{
							width: "337px",
							overflowWrap: "break-word",
						}}
						className="printable-sc-similar-label-values"
					>
						{scorecard.similarLabels.join(", ")}
					</div>
				</div>
			);
		}
	}
	let rows = [];
	for (let a = 0; a < tableData.points.length; a++) {
		let pt = tableData.points[a];
		if (pt.selected === true) {
			let itemKey = "statTableRow_" + a.toString();
			rows.push(
				<StatTableMainRow
					key={itemKey}
					pointData={pt}
					isMain={true}
				></StatTableMainRow>
			);
			for (let b = 0; b < pt.points.length; b++) {
				let pt2 = pt.points[b];
				if (pt2.selected === true) {
					let itemKey2 =
						"statTableRow2_" + b.toString() + "_" + a.toString();
					rows.push(
						<StatTableMainRow
							key={itemKey2}
							pointData={pt2}
							isMain={false}
						></StatTableMainRow>
					);
				}
			}
		}
	}
	return (
		<div className="print-sc-stat-table">
			<div className="print-sc-stat-bordered">
				<table style={{ width: "100%" }} cellPadding="5">
					<tbody>
						<tr className="back-stat-table-row">
							<td
								style={{
									fontWeight: "600",
									color: "#56B8BB",
									paddingLeft: "10px",
								}}
							>
								{tableData.title}
							</td>
							<td style={{ width: "40px", textAlign: "center" }}>
								<strong>{t("spi").toUpperCase()}</strong>
								<br />
								<span className="text-rameez-orange">
									<strong>
										{tableData.data.valueRank &&
										tableData.data.valueRank.value !== null
											? Number(
													tableData.data.valueRank
														.value
											  ).toFixed(0)
											: "N/A"}
									</strong>
								</span>
							</td>
							<td style={{ width: "40px", textAlign: "center" }}>
								<strong>{t("rank").toUpperCase()}</strong>
								<br />
								<span className="text-rameez-orange">
									<strong>
										{tableData.data.valueRank &&
										tableData.data.valueRank.value !== null
											? Number(
													tableData.data.valueRank
														.value
											  ).toFixed(0)
											: "N/A"}
									</strong>
								</span>
							</td>
							<td style={{ width: "40px" }}>
								<div
									style={{ margin: "auto" }}
									className={GetPerformanceClassName(
										tableData.data.valueRank?.performance,
										"w-4 h-4"
									)}
								/>
							</td>
						</tr>
						{rows}
					</tbody>
				</table>
			</div>
			{similar}
		</div>
	);
};

export {
	StatHeaderInfoTable,
	StatInfoTable,
	StatTitleTable,
	PrintableScoreCardBackStat,
};
