import axios from "../axios";

const getMoreInfoByKey = async (key) => {
	try {
		const response = axios.get(`/moreInfo/${key}`);
		return response;
	} catch (err) {
		throw new Error(err?.response?.data || err.message);
	}
};

export default getMoreInfoByKey;
