const twoToneColors = [
	"#943926",
	"#993e29",
	"#9c422a",
	"#a0462c",
	"#a44b2d",
	"#a74e2e",
	"#ac5230",
	"#af5532",
	"#b35934",
	"#b75f37",
	"#ba6338",
	"#bf6739",
	"#c36b3b",
	"#c76f3d",
	"#ca733e",
	"#cf7842",
	"#d47b43",
	"#d68043",
	"#db8344",
	"#e08848",
	"#e38c49",
	"#e79049",
	"#ea934c",
	"#ee984d",
	"#f29c4f",
	"#f3a054",
	"#f2a259",
	"#f2a55f",
	"#f1a864",
	"#f1ac6b",
	"#efad70",
	"#eeb075",
	"#eeb27c",
	"#eeb482",
	"#edb886",
	"#ebbb8d",
	"#eabd93",
	"#ecc099",
	"#e9c39e",
	"#e9c5a3",
	"#e7c8ab",
	"#e9cbb1",
	"#e7cdb6",
	"#e6d0bb",
	"#e5d2c1",
	"#e4d7c7",
	"#e4d8cc",
	"#e4dbd4",
	"#e2ddd9",
	"#e2e1df",
	"#dfe0e2",
	"#dadee1",
	"#d7dce0",
	"#d3dbde",
	"#cfdae0",
	"#ccd7dd",
	"#c7d5de",
	"#c3d4dc",
	"#c0d2de",
	"#bdcfdd",
	"#b8cedb",
	"#b5ccdc",
	"#b0cadb",
	"#adc8db",
	"#aac6db",
	"#a5c6d9",
	"#a0c3d9",
	"#9ec2da",
	"#9bc1d8",
	"#96bed7",
	"#92bbd7",
	"#8ebbd8",
	"#8cb9d6",
	"#87b5d6",
	"#83b5d6",
	"#81b3d4",
	"#7eb0d1",
	"#7cadce",
	"#79aacb",
	"#77a8c9",
	"#75a5c9",
	"#73a4c5",
	"#72a0c4",
	"#6f9dbf",
	"#6c9abe",
	"#6b97bc",
	"#6896ba",
	"#6692b7",
	"#638fb4",
	"#618db2",
	"#5f8bb0",
	"#5d87af",
	"#5b85ab",
	"#5a81aa",
	"#557fa5",
	"#537da3",
	"#537aa3",
	"#5077a0",
	"#4e739d",
	"#4c719b",
];

const oneToneColors = [
	"#dfe0e2",
	"#dadee1",
	"#d7dce0",
	"#d3dbde",
	"#cfdae0",
	"#ccd7dd",
	"#c7d5de",
	"#c3d4dc",
	"#c0d2de",
	"#bdcfdd",
	"#b8cedb",
	"#b5ccdc",
	"#b0cadb",
	"#adc8db",
	"#aac6db",
	"#a5c6d9",
	"#a0c3d9",
	"#9ec2da",
	"#9bc1d8",
	"#96bed7",
	"#92bbd7",
	"#8ebbd8",
	"#8cb9d6",
	"#87b5d6",
	"#83b5d6",
	"#81b3d4",
	"#7eb0d1",
	"#7cadce",
	"#79aacb",
	"#77a8c9",
	"#75a5c9",
	"#73a4c5",
	"#72a0c4",
	"#6f9dbf",
	"#6c9abe",
	"#6b97bc",
	"#6896ba",
	"#6692b7",
	"#638fb4",
	"#618db2",
	"#5f8bb0",
	"#5d87af",
	"#5b85ab",
	"#5a81aa",
	"#557fa5",
	"#537da3",
	"#537aa3",
	"#5077a0",
	"#4e739d",
	"#4c719b",
];

const getMinMax = (data: number[]): any => {
	const sortedData = data.slice().sort((a, b) => a - b);
	return { min: sortedData[0], max: sortedData[sortedData.length - 1] };
};

const getNormalizedValue = (
	value: number,
	min: number,
	max: number
): number => {
	return (value - min) / (max - min);
};

const determineColorArray = (baseLayerType: string): string[] => {
	return baseLayerType === "demographics" ? oneToneColors : twoToneColors;
};

const getLinearColorIndex = (
	value: number,
	data: number[],
	colors: string[]
): number => {
	const { min, max } = getMinMax(data);
	const normalizedValue = getNormalizedValue(value, min, max);
	return Math.round(colors.length * normalizedValue);
};

const getColorByIndex = (index: number, colors: string[]): string => {
	if (index >= colors.length) index = colors.length - 1;
	if (index < 0) index = 0;
	return colors[index];
};

export {
	oneToneColors,
	twoToneColors,
	determineColorArray,
	getLinearColorIndex,
	getColorByIndex,
};
