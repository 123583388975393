import { useTranslation } from "react-i18next";
import Paper from "../Paper";
import PartialScoreCard from "./PartialScoreCard";
import { ignoreCensus } from "../../lib/helpers";
import PrintableScoreCard from "./PrintableScoreCard";
import FullScoreCard from "./FullScoreCard";
// import FullScoreCard from "./FullScoreCard";

const ScoreCard = ({
	selectedGeo,
	scorecard,
	partial = true,
	printed = false,
	onClick,
	onClickSimilar,
	totalAreas,
	setScorecardView,
}) => {
	const { t } = useTranslation();

	if (ignoreCensus[selectedGeo.geoId]) {
		return (
			<Paper>
				<div style={{ padding: "15px" }}>
					<h3 id="geo-title" className="text-2xl mr-auto mb-4">
						{t("census_tract")}&nbsp;:&nbsp;
						{selectedGeo.geoId === "Notice"
							? t("notice")
							: selectedGeo.geoId}
					</h3>
					<div>
						{t(ignoreCensus[selectedGeo.geoId].message.toString())}
					</div>
				</div>
			</Paper>
		);
	}
	if (!scorecard) {
		return (
			<Paper>
				<div style={{ padding: "15px" }}>
					<h3 id="geo-title" className="text-2xl mr-auto mb-4">
						{t("outside_oc")}
					</h3>
					{/* <div>{t('no_data')}.</div> */}
				</div>
			</Paper>
		);
	}
	if (partial) {
		return (
			<PartialScoreCard
				scorecard={scorecard}
				cardCut={selectedGeo.cut}
				onClick={onClick}
				totalAreas={totalAreas}
				handlePeerClick={onClickSimilar}
			/>
		);
	}
	if (printed) {
		return (
			<PrintableScoreCard
				scorecard={scorecard}
				cardCut={selectedGeo.cut}
				onClick={onClick}
				onClickSimilar={onClickSimilar}
				totalAreas={totalAreas}
				setScorecardView={setScorecardView}
			/>
		);
	}
	return (
		<FullScoreCard
			scorecard={scorecard}
			cardCut={selectedGeo.cut}
			onClick={onClick}
			onClickSimilar={onClickSimilar}
			totalAreas={totalAreas}
			setScorecardView={setScorecardView}
		/>
	);
	// return (
	// 	<PrintableScoreCard
	// 		scorecard={scorecard}
	// 		cardCut={selectedGeo.cut}
	// 		onClickSimilar={onClickSimilar}
	// 		totalAreas={totalAreas}
	// 	/>
	// );
};

export default ScoreCard;
