import axios from "../axios";

const getBaselayerDataByLayer = async (year, geo, layer) => {
	try {
		const response = axios.get(`/baselayers/${year}/${geo}/${layer}`);
		return response;
	} catch (err) {
		throw new Error(err?.response?.data || err.message);
	}
};

export default getBaselayerDataByLayer;
