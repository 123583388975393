/* eslint-disable */

import { useTranslation } from "react-i18next";
import IndicatorBar from "./IndicatorBar";
import { GetPerformanceClassName } from "./PerformanceClass";

const determineValue = (val: any) => {
	if (isNaN(val)) {
		return <strong>{val}</strong>;
	} else if (val < 1) {
		if (val * 100 < 1) {
			return `${(val * 100).toFixed(2)}%`;
		}
		return `${(val * 100).toFixed(0)}%`;
	} else {
		return val.toFixed(0);
	}
};

const StatInfoTable = ({ pointData, isMain, totalAreas, moreInfoClick }) => {
	const { t } = useTranslation();
	let spi = isMain ? pointData.data.valueRank?.value : pointData.data.value;
	let rank = isMain ? pointData.data.valueRank?.rank : pointData.data.rank;

	return (
		<div>
			<div className="stat-layout-big">
				<table
					style={{
						width: "calc(100% - 180px)",
						marginTop: "5px",
					}}
				>
					<tbody>
						<tr
							className="stat-table-row"
							style={{
								display: "flex",
								alignItems: "center",
								gap: "1rem",
							}}
						>
							<td
								style={{
									width: "250px",
									padding: "0 20px",
								}}
							>
								<IndicatorBar
									width="100%"
									spi={spi}
									rank={rank}
									totalAreas={totalAreas}
								></IndicatorBar>
							</td>
							<td
								className="spi font-bold"
								style={{ width: "80px" }}
							>
								<div style={{ display: "inline-block" }}>
									<img
										src="assets/images/spi_marker_half.png"
										style={{
											height: "10px",
											display: "inline",
											marginTop: "-3px",
										}}
									/>
								</div>
								<div style={{ display: "inline-block" }}>
									<b>{t("spi").toUpperCase()}</b>
								</div>
								<br />
								<span className="text-rameez-orange">
									{determineValue(spi)}
								</span>
							</td>
							<td
								className="spi font-bold"
								style={{ width: "80px" }}
							>
								<div
									style={{
										display: "inline-block",
									}}
								>
									<img
										src="assets/images/rank_marker_half.png"
										style={{
											height: "11px",
											display: "inline",
											marginTop: "-3px",
										}}
									/>
								</div>
								<div style={{ display: "inline-block" }}>
									<b>{t("rank").toUpperCase()}</b>
								</div>
								<br />
								<span className="text-rameez-orange">
									{isNaN(rank) ? (
										<strong>N/A</strong>
									) : (
										rank.toFixed(0)
									)}
								</span>
							</td>
							<td
								style={{
									width: "100px",
									display: "flex",
									justifyContent: "center",
								}}
							>
								<div
									className={GetPerformanceClassName(
										isMain
											? pointData.data.valueRank
													?.performance
											: pointData.data.performance,
										"w-6 h-6"
									)}
								/>
							</td>
							<td className="compare-to">
								{/* Compared<br />to last year: */}
								{t("compared_to")}
								<br />
								{t("last_year")}
							</td>
							<td
								style={{ width: "25px", paddingLeft: "10px" }}
								className="text-rameez-orange"
							>
								{pointData.data.compared_to_last_year ===
								"N/A" ? (
									"N/A"
								) : pointData.data.compared_to_last_year > 0 ? (
									<img
										src="assets/images/arrow_top_right.png"
										style={{ width: "15px" }}
									/>
								) : pointData.data.compared_to_last_year ===
								  0 ? (
									<img
										src="assets/images/arrow_neutral.png"
										style={{ width: "15px" }}
									/>
								) : (
									<img
										src="assets/images/arrow_down_right.png"
										style={{ width: "15px" }}
									/>
								)}
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div className="stat-layout-small">
				<div style={{ marginTop: "5px" }}>
					<IndicatorBar
						width="100%"
						spi={spi}
						rank={rank}
						totalAreas={totalAreas}
					></IndicatorBar>
				</div>
				<table style={{ width: "100%", marginTop: "10px" }}>
					<tbody>
						<tr className="stat-table-row">
							<td className="spi font-bold">
								<div style={{ display: "inline-block" }}>
									<img
										src="assets/images/spi_marker_half.png"
										style={{
											height: "7px",
											display: "inline",
											marginTop: "-2px",
										}}
									/>
								</div>
								<div style={{ display: "inline-block" }}>
									<b>{t("spi").toUpperCase()}</b>
								</div>
								<br />
								<span className="text-rameez-orange">
									{isNaN(spi) ? (
										<strong>N/A</strong>
									) : (
										Number(Number(spi).toFixed(0))
									)}
								</span>
							</td>
							<td className="spi font-bold">
								<div style={{ display: "inline-block" }}>
									<img
										src="assets/images/rank_marker_half.png"
										style={{
											height: "8px",
											display: "inline",
											marginTop: "-3px",
										}}
									/>
								</div>
								<div style={{ display: "inline-block" }}>
									<b>{t("rank").toUpperCase()}</b>
								</div>
								<br />
								<span className="text-rameez-orange">
									{/* {rank.toFixed(0)} */}
									{isNaN(rank) ? (
										<strong>N/A</strong>
									) : (
										Number(Number(rank).toFixed(0))
									)}
								</span>
							</td>
							<td style={{ width: "50px" }}>
								<div
									style={{ margin: "auto" }}
									className={GetPerformanceClassName(
										isMain
											? pointData.data.valueRank
													?.performance
											: pointData.data.performance,
										"w-6 h-6"
									)}
								/>
							</td>
							<td className="compare-to">
								{/* Compared<br />to last year: */}
								{t("compared_to")}
								<br />
								{t("last_year")}
							</td>
							<td
								style={{ width: "20px" }}
								className="text-rameez-orange"
							>
								{pointData.data.compared_to_last_year ===
								null ? (
									"N/A"
								) : pointData.data.compared_to_last_year > 0 ? (
									<img
										src="assets/images/arrow_top_right.png"
										style={{ width: "15px" }}
									/>
								) : (
									<img
										src="assets/images/arrow_down_right.png"
										style={{ width: "15px" }}
									/>
								)}
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	);
};

const StatTitleTable = ({ pointData, isMain, moreInfoClick }) => {
	const { t } = useTranslation();
	return (
		<div>
			<div className="stat-layout-big">
				<table style={{ width: "100%", marginTop: "10px" }}>
					<tbody>
						<tr className="stat-title-row">
							<td>
								{!isMain && (
									<span>
										<img
											src="assets/images/arrow_datapoint_left.png"
											style={{
												display: "inline",
												height: "15px",
											}}
										/>
										&nbsp;&nbsp;&nbsp;
									</span>
								)}
								{isMain ? (
									<b>{pointData.title}</b>
								) : (
									pointData.title
								)}
							</td>
							<td style={{ width: "100px" }}>
								{!isMain && (
									<div
										className="stat-table-more-info"
										onClick={(ev) => {
											moreInfoClick(ev, pointData);
										}}
									>
										{t("more_info")}
									</div>
								)}
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div className="stat-layout-small">
				<table style={{ width: "100%", marginTop: "10px" }}>
					<tbody>
						<tr className="stat-title-row">
							<td>
								{isMain ? (
									<b>{pointData.title}</b>
								) : (
									pointData.title
								)}
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	);
};

const StatTableMainRow = ({ pointData, isMain, totalAreas, moreInfoClick }) => {
	let className = "stat-table-container";
	if (isMain) {
		className += " container-main";
	}
	let infoClassName = "stat-info-container";
	let mainClassName = "";
	if (!isMain) {
		mainClassName = "stat-layout-big";
	}

	return (
		<div className={mainClassName}>
			<div className={className}>
				<StatTitleTable
					pointData={pointData}
					isMain={isMain}
					moreInfoClick={moreInfoClick}
				></StatTitleTable>
			</div>
			<div className={infoClassName}>
				<StatInfoTable
					pointData={pointData}
					isMain={isMain}
					totalAreas={totalAreas}
					moreInfoClick={moreInfoClick}
				></StatInfoTable>
			</div>
			<div style={{ marginTop: "10px" }}>
				<hr style={{ width: "100%" }} />
			</div>
		</div>
	);
};

const FullScoreCardStat = ({
	tableData,
	onClick,
	totalAreas,
	moreInfoClick,
}) => {
	let imgArrow = <img src="assets/images/white_arrow_down.png" />;
	if (tableData.selected) {
		imgArrow = <img src="assets/images/white_arrow_up.png" />;
	}
	//console.log("tableData.selected: "+tableData.selected);
	let rows = [];
	if (tableData.selected === true) {
		for (let a = 0; a < tableData.points.length; a++) {
			let pt = tableData.points[a];
			if (pt.selected === true) {
				let itemKey = "statTableRow_" + a.toString();
				rows.push(
					<StatTableMainRow
						key={itemKey}
						pointData={pt}
						isMain={true}
						totalAreas={totalAreas}
						moreInfoClick={moreInfoClick}
					></StatTableMainRow>
				);
				for (let b = 0; b < pt.points.length; b++) {
					let pt2 = pt.points[b];
					if (pt2.selected === true) {
						let itemKey2 =
							"statTableRow2_" +
							b.toString() +
							"_" +
							a.toString();
						rows.push(
							<StatTableMainRow
								key={itemKey2}
								pointData={pt2}
								isMain={false}
								totalAreas={totalAreas}
								moreInfoClick={moreInfoClick}
							></StatTableMainRow>
						);
					}
				}
			}
		}
	}
	if (tableData.selected === false) {
		return (
			<div>
				<div className="stat-layout-big"></div>
				<div className="stat-layout-small full-sc-stat-table">
					<div
						className="table-title"
						onClick={() => {
							onClick(tableData);
						}}
					>
						{tableData.title}
						<div className="toggle-arrow">{imgArrow}</div>
					</div>
				</div>
			</div>
		);
	}
	return (
		<div className="full-sc-stat-table">
			<div className="stat-layout-big">
				<div className="table-title">{tableData.title}</div>
				<div className="separator"></div>
			</div>
			<div className="stat-layout-small">
				<div
					className="table-title"
					onClick={() => {
						onClick(tableData);
					}}
				>
					{tableData.title}
					<div className="toggle-arrow">{imgArrow}</div>
				</div>
				<div className="stat-info-main">
					<StatInfoTable
						pointData={tableData}
						isMain={true}
						totalAreas={totalAreas}
						moreInfoClick={moreInfoClick}
					></StatInfoTable>
				</div>
			</div>
			{rows}
		</div>
	);
};

export { FullScoreCardStat };
