import { useTranslation } from "react-i18next";
import { Performance } from "../../generated/types";
import { GetPerformanceClassName } from "./PerformanceClass";

const GetScoreCardLegends = () => {
	const { t } = useTranslation();
	let vals = [
		{ title: t("expected"), performance: Performance.S },
		{ title: t("overperforming"), performance: Performance.E },
		{ title: t("underperforming"), performance: Performance.I },
		{ title: t("very_overperforming"), performance: Performance.O },
		{ title: t("very_underperforming"), performance: Performance.U },
	];
	return vals;
};

const ScoreCardIndicator = (props) => {
	let wh = props.wh;
	let className = "w-" + wh + " h-" + wh;
	return (
		<div
			className={GetPerformanceClassName(props.performance, className)}
			style={{ display: "inline-block" }}
		></div>
	);
};

// const ScoreCardLegend = (props) => {

// }

const ScoreCardLegends = (props) => {
	const { t } = useTranslation();
	let legends = GetScoreCardLegends();
	let rows = [];
	for (let a = 0; a < legends.length; a++) {
		rows.push(
			<tr key={a + 1} className="scorecard-legend">
				<td className="title">{legends[a].title}</td>
				<td style={{ textAlign: "right" }}>
					<ScoreCardIndicator
						wh={props.wh}
						performance={legends[a].performance}
					></ScoreCardIndicator>
				</td>
			</tr>
		);
	}
	return (
		<div>
			<table className="scorecard-legends">
				<tbody>
					<tr key="0" className="scorecard-legend">
						<td className="title font-bold">{t("legend")}:</td>
						<td>&nbsp;</td>
					</tr>
					{rows}
				</tbody>
			</table>
		</div>
	);
};

export { ScoreCardLegends };
