/* eslint-disable */
import { Cut } from "../../generated/types";
import censusTopoJson from "../../lib/maps/topojson/census_tracts.json";
import cityTopoJson from "../../lib/maps/topojson/cities.json";
import zipTopoJson from "../../lib/maps/topojson/zipcodes.json";
import censusGeoJson from "../../lib/maps/geojson/census_tracts.json";
import cityGeoJson from "../../lib/maps/geojson/cities.json";
import cityBoundaryJson from "../../lib/maps/geojson/cities_boundary.json";
import zipGeoJson from "../../lib/maps/geojson/zipcodes.json";
import zipBoundaryJson from "../../lib/maps/geojson/zip_boundary.json";
import outsideOcGeoJson from "../../lib/maps/geojson/white_sections.json";
import schoolDistrictsGeoJson from "../../lib/maps/geojson/school_districts.json";

const GetOutsideOcGeoJson = (): any => {
  return outsideOcGeoJson;
};

const GetCityBoundaryJson = () => {
  return cityBoundaryJson;
};

const GetZipBoundaryJson = () => {
  return zipBoundaryJson;
};

const GetZipGeoJson = (geo: any) => {
  const zip = geo?.geoId;
  if (!zip) return { ...zipGeoJson, features: [] };
  const filteredZipJson = {
    ...zipGeoJson,
    features: zipGeoJson.features.filter(
      (feature) => feature.properties.GEOID10 != zip
    ),
  };
  return filteredZipJson;
};

const GetCityGeoJson = (geo: any) => {
  const city = geo?.geoId;
  if (!city) return { ...cityGeoJson, features: [] };
  const filteredCityJson = {
    ...cityGeoJson,
    features: cityGeoJson.features.filter(
      (feature) => feature.properties.CITY != city
    ),
  };
  return filteredCityJson;
};

const GetGlobeJson = (): any => {
  return {};
};

const GetSchoolDistrictsGeoJson = () => {
  return schoolDistrictsGeoJson;
};

const GetMapGeoJson = (cut: Cut): any => {
  switch (cut) {
    case Cut.City:
       return cityGeoJson;
    case Cut.ZipCode:
      return zipGeoJson;
    default:
      return censusGeoJson;
  }
};

const GetMapJson = (cut: Cut): any => {
  switch (cut) {
    case Cut.City:
      return cityTopoJson;
    case Cut.ZipCode:
      return zipTopoJson;
    default:
      return censusTopoJson;
  }
};

const GetIdsFromTopoJson = (json: any) => {
  let type = json["type"];
  let data = [];
  if (type == "Topology") {
    data = json["objects"]["parsed"]["geometries"];
  } else if (type == "FeatureCollection") {
    data = json["features"];
  }
  let ids = [];
  for (let a = 0; a < data.length; a++) {
    let g = data[a];
    let prop = g["properties"];
    let id = prop.ZCTA5CE10 || prop.CITY || prop.geoid;
    if (id == "UNINCORPORATED") continue;
    ids.push(id);
  }
  return ids;
};

const GetIdsFromJson = (cut: Cut): any => {
  if (cut == Cut.CensusTract) {
    return GetIdsFromTopoJson(censusGeoJson);
  } else {
    let json = GetMapJson(cut);
    return GetIdsFromTopoJson(json);
  }
};

export {
  GetGlobeJson,
  GetMapGeoJson,
  GetMapJson,
  GetIdsFromJson,
  GetSchoolDistrictsGeoJson,
  GetZipGeoJson,
  GetCityGeoJson,
  GetCityBoundaryJson,
  GetZipBoundaryJson,
  GetOutsideOcGeoJson,
};
