/* eslint-disable */

const FilterPointControl = ({ pointData, onClick }) => {
	const pointClick = () => {
		onClick(pointData, "3");
	};
	let className = "filter-point";
	if (pointData.selected === true) {
		className += " point-selected";
	}
	return (
		<div className={className}>
			<a href="#" onClick={pointClick} style={{ display: "block" }}>
				<div className="title">{pointData.title}</div>
				<div className="toggle">
					<div className="circle"></div>
				</div>
				<div style={{ clear: "both" }}></div>
			</a>
		</div>
	);
};

const FilterSubMainControl = ({ pointData, onClick }) => {
	const pointClick = () => {
		onClick(pointData, "2");
	};
	let allPoints = [];
	for (let a = 0; a < pointData.points.length; a++) {
		if (pointData.selected === true) {
			allPoints.push(
				<FilterPointControl
					key={a}
					pointData={pointData.points[a]}
					onClick={onClick}
				></FilterPointControl>
			);
		}
	}
	let className = "subheader";
	if (pointData.selected === true) {
		className += " point-selected";
	}
	return (
		<div>
			<div className={className}>
				<a href="#" onClick={pointClick} style={{ display: "block" }}>
					<div className="title">{pointData.title}</div>
					<div className="toggle">
						<div className="circle"></div>
					</div>
					<div style={{ clear: "both" }}></div>
				</a>
			</div>
			<div className="scorecard-filter-points-container">{allPoints}</div>
		</div>
	);
};

const FullScoreCardFilters = ({ pointData, onClick }) => {
	const pointClick = () => {
		onClick(pointData, "1");
	};
	let allPoints = [];
	for (let a = 0; a < pointData.points.length; a++) {
		if (pointData.selected === true) {
			allPoints.push(
				<FilterSubMainControl
					key={a}
					pointData={pointData.points[a]}
					onClick={onClick}
				></FilterSubMainControl>
			);
		}
	}
	let imgArrow = <img src="assets/images/white_arrow_down.png" />;
	if (pointData.selected) {
		imgArrow = <img src="assets/images/white_arrow_up.png" />;
	}
	return (
		<div className="scorecard-filters">
			<div
				className="header"
				onClick={pointClick}
				style={{ cursor: "pointer" }}
			>
				<a href="#">{pointData.title}</a>
				<div className="toggle-arrow">{imgArrow}</div>
			</div>
			<div className="scorecard-filter-subheaders-container">
				{allPoints}
			</div>
		</div>
	);
};

export { FullScoreCardFilters };
