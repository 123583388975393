import { useTranslation } from "react-i18next";
import { ScorecardInfo } from "../../generated/types";

const GetAllDataPoints = (scorecard: ScorecardInfo) => {
	const { t } = useTranslation();
	let tableData = [
		{
			title: t("basic_human_needs"),
			title_key: "basic_human_needs",
			data: scorecard.bhn,
			selected: true,
			points: [
				{
					title: t("NBMData"),
					title_key: "NBMData",
					data: scorecard.bhn.nbm,
					selected: true,
					points: [
						{
							title: t("dental_care_visits_adults"),
							title_key: "dental_care_visits_adults",
							data: scorecard.bhn.nbm.nbm_dental,
							mi_key: "nbm_dental",
							selected: true,
							points: [],
						},
						{
							title: t(
								"households_with_food_stamps_12_months_percent_households"
							),
							title_key:
								"households_with_food_stamps_12_months_percent_households",
							data: scorecard.bhn.nbm.nbm_foodstamps,
							mi_key: "nbm_foodstamps",
							selected: true,
							points: [],
						},
						{
							title: t("preventative_care_visits_percent_adults"),
							title_key:
								"preventative_care_visits_percent_adults",
							data: scorecard.bhn.nbm.nbm_preventative,
							mi_key: "nbm_preventative",
							selected: true,
							points: [],
						},
						{
							title: t("supermarket_access_percent_population"),
							title_key: "supermarket_access_percent_population",
							data: scorecard.bhn.nbm.nbm_supermarketaccess,
							mi_key: "nbm_supermarketaccess",
							selected: true,
							points: [],
						},
						{
							title: t(
								"kintergarten_vaccination_rate_percent_kintergarteners"
							),
							title_key:
								"kintergarten_vaccination_rate_percent_kintergarteners",
							data: scorecard.bhn.nbm.nbm_vax,
							mi_key: "nbm_vax",
							selected: true,
							points: [],
						},
					],
				},
				{
					title: t("WSData"),
					title_key: "WSData",
					data: scorecard.bhn.ws,
					selected: true,
					points: [
						// {
						//   title: t("safe_drinking_water_act_health_based_violations"),
						//   title_key: "safe_drinking_water_act_health_based_violations",
						//   data: scorecard.bhn.ws.ws_drinkingwater,
						//   mi_key: "ws_drinkingwater",
						//   selected: true,
						//   points: [],
						// },
						{
							title: t("Groundwater"),
							title_key: "Groundwater",
							data: scorecard.bhn.ws.ws_groundwater,
							mi_key: "ws_groundwater",
							selected: true,
							points: [],
						},
						{
							title: t(
								"resource_conservation_and_recovery_act_hazardous_waste_violations"
							),
							title_key:
								"resource_conservation_and_recovery_act_hazardous_waste_violations",
							data: scorecard.bhn.ws.ws_hazardouswaste,
							mi_key: "ws_hazardouswaste",
							selected: true,
							points: [],
						},
						{
							title: t(
								"businesses_in_compliance_with_health_standards_percent_businesses"
							),
							title_key:
								"businesses_in_compliance_with_health_standards_percent_businesses",
							data: scorecard.bhn.ws.ws_inspections,
							mi_key: "ws_inspections",
							selected: true,
							points: [],
						},
					],
				},
				{
					title: t("HousingData"),
					title_key: "HousingData",
					data: scorecard.bhn.housing,
					selected: true,
					points: [
						// {
						// 	title: t("eviction_rate_per_100_renter_homes"),
						// 	title_key: "eviction_rate_per_100_renter_homes",
						// 	data: scorecard.bhn.housing.s_eviction,
						// 	mi_key: "s_eviction",
						// 	selected: true,
						// 	points: [],
						// },
						{
							title: t(
								"housing_cost_burden_percent_households_owners"
							),
							title_key:
								"housing_cost_burden_percent_households_owners",
							data: scorecard.bhn.housing.s_hburdenowner,
							mi_key: "s_hburdenowner",
							selected: true,
							points: [],
						},
						{
							title: t(
								"housing_cost_burden_percent_households_renters"
							),
							title_key:
								"housing_cost_burden_percent_households_renters",
							data: scorecard.bhn.housing.s_hburdenrenter,
							mi_key: "s_hburdenrenter",
							selected: true,
							points: [],
						},
						{
							title: t("housing_overcrowding_percent_households"),
							title_key:
								"housing_overcrowding_percent_households",
							data: scorecard.bhn.housing.s_overcrowded,
							mi_key: "s_overcrowded",
							selected: true,
							points: [],
						},
						{
							title: t(
								"housing_rental_assistance_priority_index"
							),
							title_key:
								"housing_rental_assistance_priority_index",
							data: scorecard.bhn.housing.s_rapi,
							mi_key: "s_rapi",
							selected: true,
							points: [],
						},
					],
				},
				{
					title: t("PSData"),
					title_key: "PSData",
					data: scorecard.bhn.ps,
					selected: true,
					points: [
						{
							title: t("motor_vehicle_accident_rate_per_10k_pop"),
							title_key:
								"motor_vehicle_accident_rate_per_10k_pop",
							data: scorecard.bhn.ps.ps_accidents,
							mi_key: "ps_accidents",
							selected: true,
							points: [],
						},
						{
							title: t("property_crime_rate_per_10k_pop"),
							title_key: "property_crime_rate_per_10k_pop",
							data: scorecard.bhn.ps.ps_property,
							mi_key: "ps_property",
							selected: true,
							points: [],
						},
						{
							title: t("violent_crime_rate_per_1k_pop"),
							title_key: "violent_crime_rate_per_1k_pop",
							data: scorecard.bhn.ps.ps_violent,
							mi_key: "ps_violent",
							selected: true,
							points: [],
						},
					],
				},
			],
		},
		{
			title: t("foundations_of_wellbeing"),
			title_key: "foundations_of_wellbeing",
			data: scorecard.wf,
			selected: true,
			points: [
				{
					title: t("HWData"),
					title_key: "HWData",
					data: scorecard.wf.hw,
					selected: true,
					points: [
						{
							title: t("cancer_prevalence_percent_adults"),
							title_key: "cancer_prevalence_percent_adults",
							data: scorecard.wf.hw.hw_cancer,
							mi_key: "hw_cancer",
							selected: true,
							points: [],
						},
						{
							title: t("diabetes_prevalence"),
							title_key: "diabetes_prevalence",
							data: scorecard.wf.hw.hw_diabetes,
							mi_key: "hw_diabetes",
							selected: true,
							points: [],
						},
						{
							title: t("poor_mental_health_days"),
							title_key: "poor_mental_health_days",
							data: scorecard.wf.hw.hw_mentalhealth,
							mi_key: "hw_mentalhealth",
							selected: true,
							points: [],
						},
						{
							title: t("obesity_prevalence"),
							title_key: "obesity_prevalence",
							data: scorecard.wf.hw.hw_obesity,
							mi_key: "hw_obesity",
							selected: true,
							points: [],
						},
						{
							title: t(
								"vulnerable_or_at_risk_physical_health_percent_children_5"
							),
							title_key:
								"vulnerable_or_at_risk_physical_health_percent_children_5",
							data: scorecard.wf.hw.hw_under5_physicalhealth,
							mi_key: "hw_under5_physicalhealth",
							selected: true,
							points: [],
						},
					],
				},
				{
					title: t("ABKData"),
					title_key: "ABKData",
					data: scorecard.wf.abk,
					selected: true,
					points: [
						{
							title: t(
								"third_grade_language_arts_proficiency_percent_3rd_graders"
							),
							title_key:
								"third_grade_language_arts_proficiency_percent_3rd_graders",
							data: scorecard.wf.abk.abk_g3reading,
							mi_key: "abk_g3reading",
							selected: true,
							points: [],
						},
						{
							title: t(
								"eighth_grade_math_proficiency_percent_8th_graders"
							),
							title_key:
								"eighth_grade_math_proficiency_percent_8th_graders",
							data: scorecard.wf.abk.abk_g8math,
							mi_key: "abk_g8math",
							selected: true,
							points: [],
						},
						{
							title: t(
								"population_without_high_school_diploma_equivalent_percent_population_18_24"
							),
							title_key:
								"population_without_high_school_diploma_equivalent_percent_population_18_24",
							data: scorecard.wf.abk.abk_hsincomplete,
							mi_key: "abk_hsincomplete",
							selected: true,
							points: [],
						},
						{
							title: t(
								"preschool_enrollment_percent_3_4_year_olds"
							),
							title_key:
								"preschool_enrollment_percent_3_4_year_olds",
							data: scorecard.wf.abk.abk_preschoolenroll,
							mi_key: "abk_preschoolenroll",
							selected: true,
							points: [],
						},
						{
							title: t(
								"communication_and_general_knowledge_percent_children_5"
							),
							title_key:
								"communication_and_general_knowledge_percent_children_5",
							data: scorecard.wf.abk.abk_under5_comskills,
							mi_key: "abk_under5_comskills",
							selected: true,
							points: [],
						},
						{
							title: t(
								"social-emotional_vulnerable_percent_children_5"
							),
							title_key:
								"social-emotional_vulnerable_percent_children_5",
							data: scorecard.wf.abk.abk_under5_socioemotional,
							mi_key: "abk_under5_socioemotional",
							selected: true,
							points: [],
						},
					],
				},
				{
					title: t("AICData"),
					title_key: "AICData",
					data: scorecard.wf.aic,
					selected: true,
					points: [
						{
							title: t("broadband_subscription_percent_pop"),
							title_key: "broadband_subscription_percent_pop",
							data: scorecard.wf.aic.aic_broadband,
							mi_key: "aic_broadband",
							selected: true,
							points: [],
						},
						{
							title: t("average_broadband_speed_mbps"),
							title_key: "average_broadband_speed_mbps",
							data: scorecard.wf.aic.aic_broadbandspeed,
							mi_key: "aic_broadbandspeed",
							selected: true,
							points: [],
						},
						{
							title: t("cellular_data_subscription_percent_pop"),
							title_key: "cellular_data_subscription_percent_pop",
							data: scorecard.wf.aic.aic_celldata,
							mi_key: "aic_celldata",
							selected: true,
							points: [],
						},
						{
							title: t(
								"one_more_types_computing_devices_present_percent_housholds"
							),
							title_key:
								"one_more_types_computing_devices_present_percent_housholds",
							data: scorecard.wf.aic.aic_hascomputer,
							mi_key: "aic_hascomputer",
							selected: true,
							points: [],
						},
						{
							title: t("no_internet_access_percent_pop"),
							title_key: "no_internet_access_percent_pop",
							data: scorecard.wf.aic.aic_nointernet,
							mi_key: "aic_nointernet",
							selected: true,
							points: [],
						},
					],
				},
				{
					title: t("EQData"),
					title_key: "EQData",
					data: scorecard.wf.eq,
					selected: true,
					points: [
						// {
						//   title: t("carbon_footprint"),
						//   title_key: "carbon_footprint",
						//   data: scorecard.wf.eq.eq_carbonfootprint,
						//   mi_key: "eq_carbonfootprint",
						//   selected: true,
						//   points: [],
						// },
						{
							title: t(
								"nitrogen_dioxide_avg_annual_concentration"
							),
							title_key:
								"nitrogen_dioxide_avg_annual_concentration",
							data: scorecard.wf.eq.eq_no2,
							mi_key: "eq_no2",
							selected: true,
							points: [],
						},
						{
							title: t("ozone_average_8-hour_concentration"),
							title_key: "ozone_average_8-hour_concentration",
							data: scorecard.wf.eq.eq_ozone,
							mi_key: "eq_ozone",
							selected: true,
							points: [],
						},
						{
							title: t("pm2_5_average_annual_concentration"),
							title_key: "pm2_5_average_annual_concentration",
							data: scorecard.wf.eq.eq_pm25,
							mi_key: "eq_pm25",
							selected: true,
							points: [],
						},
						{
							title: t("wildfire_hazard_potential"),
							title_key: "wildfire_hazard_potential",
							data: scorecard.wf.eq.eq_wildfirehp,
							mi_key: "eq_wildfirehp",
							selected: true,
							points: [],
						},
					],
				},
			],
		},
		{
			title: t("opportunity"),
			title_key: "opportunity",
			data: scorecard.opportunity,
			selected: true,
			points: [
				{
					title: t("PRData"),
					title_key: "PRData",
					data: scorecard.opportunity.pr,
					selected: true,
					points: [
						{
							title: t("home_ownership"),
							title_key: "home_ownership",
							data: scorecard.opportunity.pr.pr_homeownership,
							mi_key: "pr_homeownership",
							selected: true,
							points: [],
						},
						{
							title: t(
								"voter_registration_percent_voting_eligible_pop"
							),
							title_key:
								"voter_registration_percent_voting_eligible_pop",
							data: scorecard.opportunity.pr.pr_registration,
							mi_key: "pr_registration",
							selected: true,
							points: [],
						},
						{
							title: t("voter_turnout_percent_eligible_pop"),
							title_key: "voter_turnout_percent_eligible_pop",
							data: scorecard.opportunity.pr.pr_turnout,
							mi_key: "pr_turnout",
							selected: true,
							points: [],
						},
					],
				},
				{
					title: t("PFCData"),
					title_key: "PFCData",
					data: scorecard.opportunity.pfc,
					selected: true,
					points: [
						{
							title: t("childcare_deserts"),
							title_key: "childcare_deserts",
							data: scorecard.opportunity.pfc
								.pfc_childcaredeserts,
							mi_key: "pfc_childcaredeserts",
							selected: true,
							points: [],
						},
						{
							title: t(
								"alternative_transportation_usage_percent_commuters"
							),
							title_key:
								"alternative_transportation_usage_percent_commuters",
							data: scorecard.opportunity.pfc.pfc_cleancommute,
							mi_key: "pfc_cleancommute",
							selected: true,
							points: [],
						},
						{
							title: t(
								"disconnected_youth_percent_16-19_year_olds"
							),
							title_key:
								"disconnected_youth_percent_16-19_year_olds",
							data: scorecard.opportunity.pfc.pfc_disconnected,
							mi_key: "pfc_disconnected",
							selected: true,
							points: [],
						},
						{
							title: t("walkability_index"),
							title_key: "walkability_index",
							data: scorecard.opportunity.pfc.pfc_walkindex,
							mi_key: "pfc_walkindex",
							selected: true,
							points: [],
						},
					],
				},
				{
					title: t("InclusivenessData"),
					title_key: "InclusivenessData",
					data: scorecard.opportunity.inclusiveness,
					selected: true,
					points: [
						{
							title: t("residential_segregation_non-white_white"),
							title_key:
								"residential_segregation_non-white_white",
							data: scorecard.opportunity.inclusiveness
								.i_dissimilarity,
							mi_key: "i_dissimilarity",
							selected: true,
							points: [],
						},
						{
							title: t("gender_pay_gap"),
							title_key: "gender_pay_gap",
							data: scorecard.opportunity.inclusiveness
								.i_genderpaygap,
							mi_key: "i_genderpaygap",
							selected: true,
							points: [],
						},
						{
							title: t("residential_isolation_non-white_white"),
							title_key: "residential_isolation_non-white_white",
							data: scorecard.opportunity.inclusiveness
								.i_isolation,
							mi_key: "i_isolation",
							selected: true,
							points: [],
						},
						{
							title: t("linguistic_isolation_percent_housholds"),
							title_key: "linguistic_isolation_percent_housholds",
							data: scorecard.opportunity.inclusiveness
								.i_linguistic,
							mi_key: "i_linguistic",
							selected: true,
							points: [],
						},
					],
				},
				{
					title: t("AAEData"),
					title_key: "AAEData",
					data: scorecard.opportunity.aae,
					selected: true,
					points: [
						{
							title: t(
								"associates_degree_holders_percent_pop_25"
							),
							title_key:
								"associates_degree_holders_percent_pop_25",
							data: scorecard.opportunity.aae.aae_associates,
							mi_key: "aae_associates",
							selected: true,
							points: [],
						},
						{
							title: t("bachelors_degree_holders_percent_pop_25"),
							title_key:
								"bachelors_degree_holders_percent_pop_25",
							data: scorecard.opportunity.aae.aae_bachelors,
							mi_key: "aae_bachelors",
							selected: true,
							points: [],
						},
						{
							title: t("advance_degree_holders_percent_pop_25"),
							title_key: "advance_degree_holders_percent_pop_25",
							data: scorecard.opportunity.aae.aae_graduate,
							mi_key: "aae_graduate",
							selected: true,
							points: [],
						},
					],
				},
			],
		},
	];
	return tableData;
};

export default GetAllDataPoints;
