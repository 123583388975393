/* eslint-disable */

import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import GetAllDataPoints from "./DataPoints";
import { PrintableScoreCardFrontStat } from "./PrintableScoreCardFrontStat";
import { PrintableScoreCardBackStat } from "./PrintableScoreCardBackStat";
import "./ScoreCard.scss";
import "./PrintableScoreCard.scss";
import { useState } from "react";
import { ScoreCardLegends } from "./ScoreCardLegends";
import ScoreCardTitle from "./ScoreCardTitle";
import IndicatorBar from "./IndicatorBar";
import { GetPerformanceClassName } from "./PerformanceClass";
import { Cut } from "../../generated/types";

const PrintableScoreCard = ({
	scorecard,
	cardCut,
	onClick,
	onClickSimilar,
	totalAreas,
	setScorecardView,
}) => {
	const [allDataPoints, setDataPoints] = useState(
		GetAllDataPoints(scorecard)
	);
	const { t } = useTranslation();
	const allTables = [];
	const allBackTables = [];

	allTables.push(
		<PrintableScoreCardFrontStat
			key="table1"
			tableData={allDataPoints[0]}
			totalAreas={totalAreas}
		></PrintableScoreCardFrontStat>
	);
	allTables.push(
		<PrintableScoreCardFrontStat
			key="table2"
			tableData={allDataPoints[1]}
			totalAreas={totalAreas}
		></PrintableScoreCardFrontStat>
	);
	allTables.push(
		<PrintableScoreCardFrontStat
			key="table3"
			tableData={allDataPoints[2]}
			totalAreas={totalAreas}
		></PrintableScoreCardFrontStat>
	);

	allBackTables.push(
		<PrintableScoreCardBackStat
			key="table1"
			tableData={allDataPoints[0]}
			scorecard={scorecard}
			displaySimilar={false}
			onClickSimilar={onClickSimilar}
		></PrintableScoreCardBackStat>
	);
	allBackTables.push(
		<PrintableScoreCardBackStat
			key="table2"
			tableData={allDataPoints[1]}
			scorecard={scorecard}
			displaySimilar={false}
			onClickSimilar={onClickSimilar}
		></PrintableScoreCardBackStat>
	);
	allBackTables.push(
		<PrintableScoreCardBackStat
			key="table3"
			tableData={allDataPoints[2]}
			scorecard={scorecard}
			displaySimilar={true}
			onClickSimilar={onClickSimilar}
		></PrintableScoreCardBackStat>
	);

	const addCanvasToPdf = (
		canvas: any,
		doc: any,
		divWidth = 1200,
		divHeight = 0
	) => {
		let imgData = canvas.toDataURL("image/png");
		let width = doc.internal.pageSize.width;
		let height = doc.internal.pageSize.height;
		doc.addImage(imgData, "PNG", 10, 10, divWidth, divHeight);
	};

	var pdfFileName =
		"Advance OC Scorecard for Census Tract " + scorecard.fullName + ".pdf";
	if (cardCut === Cut.City) {
		pdfFileName =
			"Advance OC Scorecard for City " + scorecard.fullName + ".pdf";
	} else if (cardCut == Cut.ZipCode) {
		pdfFileName =
			"Advance OC Scorecard for Zip Code " + scorecard.fullName + ".pdf";
	}

	const printTheScoreCard = (saveFile: boolean = true) => {
		let wd: any = window;
		let page1 = document.getElementById("printablescorecardpage1");
		let page2 = document.getElementById("printablescorecardpage2");

		const letterSize = { width: 1275, height: 1650 };

		const pdfWidthPage1 = letterSize.height;
		const pdfHeightPage1 = letterSize.width;
		const pdfOrientationPage1 = "landscape";

		var doc = new wd.jsPDF(pdfOrientationPage1, "px", [
			pdfWidthPage1,
			pdfHeightPage1,
		]);

		const scalePage1 = Math.min(
			pdfWidthPage1 / page1.clientWidth,
			pdfHeightPage1 / page1.clientHeight
		);

		wd.html2canvas(page1, { allowTaint: true, scale: scalePage1 }).then(
			function (canvas: any) {
				addCanvasToPdf(
					canvas,
					doc,
					page1.clientWidth * scalePage1,
					page1.clientHeight * scalePage1
				);

				const pdfWidthPage2 = letterSize.width;
				const pdfHeightPage2 = letterSize.height;

				doc.addPage([pdfWidthPage2, pdfHeightPage2], "portrait");

				const scalePage2 = Math.min(
					pdfWidthPage2 / page2.clientWidth,
					pdfHeightPage2 / page2.clientHeight
				);

				wd.html2canvas(page2, {
					allowTaint: true,
					scale: scalePage2,
				}).then(function (canvas: any) {
					addCanvasToPdf(
						canvas,
						doc,
						page2.clientWidth * scalePage2,
						page2.clientHeight * scalePage2
					);

					if (saveFile) {
						doc.save(pdfFileName);
					}

					setTimeout(() => {
						setScorecardView({ partial: false, printed: false });
					}, 2000);
				});
			}
		);
	};

	const printIt = (saveFile: boolean = true) => {
		let p1 = document.getElementById("scorecardpage1");
		let p2 = document.getElementById("scorecardpage2");
		let pp1 = document.getElementById("printablescorecardpage1");
		let pp2 = document.getElementById("printablescorecardpage2");
		pp1.innerHTML = p1.innerHTML;
		let pageHtml =
			"<div style='text-align: center;font-size: 0.80rem;padding: 5px;'>" +
			t("page") +
			" 1/2 " +
			t("of_census_tract") +
			": " +
			scorecard.name +
			"</div>";
		pp1.innerHTML += pageHtml;
		pp2.innerHTML = p2.innerHTML;
		pageHtml =
			"<div style='text-align: center;font-size: 0.80rem;padding: 5px;'>" +
			t("page") +
			" 2/2 " +
			t("of_census_tract") +
			": " +
			scorecard.name +
			"</div>";
		pp2.innerHTML += pageHtml;
		printTheScoreCard(saveFile);
	};

	useEffect(() => {
		printIt(true);
	}, []);

	let spi = scorecard.spi ? scorecard.spi.value : 1;
	let rank = scorecard.spi ? scorecard.spi.rank : 1;

	let mapUrl = <></>;

	return (
		<>
			<div
				id="printablescorecard"
				style={{
					position: "absolute",
					left: "-1400px",
					top: "0px",
					width: "1200px",
					margin: "auto",
					padding: "10px",
					zIndex: "999999 !important",
				}}
			>
				<div id="printablescorecardpage1"></div>
				<div id="printablescorecardpage2"></div>
			</div>
			<div id="scorecard">
				<div id="scorecardpage1">
					<div className="print-sc-top-container">
						<div className="print-sc-top-part left">
							<div style={{ float: "left" }}>
								<div>
									<img
										src="assets/images/AdvanceOC_logo_color_48.png"
										alt=""
									/>
								</div>
							</div>
							<div
								style={{
									float: "left",
									width: "250px",
									marginLeft: "15px",
								}}
							>
								<ScoreCardTitle
									scorecard={scorecard}
									cardCut={cardCut}
								></ScoreCardTitle>
							</div>
							<div style={{ float: "left", marginLeft: "15px" }}>
								<div style={{ float: "left" }}>
									<div
										className="font-bold"
										style={{
											display: "inline-block",
											width: "110px",
											textAlign: "center",
											backgroundColor:
												"rgba(86, 184, 187,0.1)",
											padding: "10px",
											borderRightWidth: "1px",
											borderRightStyle: "solid",
											borderRightColor:
												"rgba(0,0,0,0.15)",
											borderTopLeftRadius: "10px",
											borderBottomLeftRadius: "10px",
										}}
									>
										<div
											style={{
												width: "50%",
												margin: "auto",
											}}
										>
											<table
												style={{ borderWidth: "0px" }}
											>
												<tbody>
													<tr>
														<td>
															<img
																src="assets/images/spi_marker_half.png"
																style={{
																	height: "12px",
																	display:
																		"inline",
																	marginTop:
																		"-3px",
																}}
																alt=""
															/>
														</td>
														<td
															style={{
																color: "#000000",
															}}
														>
															<b>
																{t(
																	"spi"
																).toUpperCase()}
															</b>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
										<div
											className="text-xl text-rameez-orange"
											style={{ marginTop: "5px" }}
										>
											{scorecard.spi.value.toFixed(0)}
										</div>
									</div>
									<div
										className="font-bold"
										style={{
											display: "inline-block",
											width: "110px",
											textAlign: "center",
											backgroundColor:
												"rgba(86, 184, 187,0.1)",
											padding: "10px",
											borderTopRightRadius: "10px",
											borderBottomRightRadius: "10px",
										}}
									>
										<div
											style={{
												width: "80%",
												margin: "auto",
											}}
										>
											<table
												style={{ borderWidth: "0px" }}
											>
												<tbody>
													<tr>
														<td>
															<img
																src="assets/images/rank_marker_half.png"
																style={{
																	height: "13px",
																	display:
																		"inline",
																	marginTop:
																		"-3px",
																}}
																alt=""
															/>
														</td>
														<td
															style={{
																color: "#000000",
															}}
														>
															<b>
																{t(
																	"rank"
																).toUpperCase()}
															</b>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
										<div
											className="text-xl text-rameez-orange"
											style={{ marginTop: "5px" }}
										>
											{scorecard.spi.rank}
										</div>
									</div>
								</div>
								<div
									style={{
										float: "left",
										marginLeft: "15px",
										paddingTop: "20px",
									}}
								>
									<div
										className={GetPerformanceClassName(
											scorecard.spi.performance,
											"w-10 h-10"
										)}
									/>
								</div>
								<div
									className="font-bold"
									style={{
										float: "left",
										marginLeft: "15px",
										marginTop: "15px",
									}}
								>
									Compared
									<br />
									to 2020:
								</div>
								<div
									style={{
										float: "left",
										marginLeft: "15px",
										marginTop: "25px",
									}}
								>
									<img
										src="assets/images/arrow_top_right.png"
										style={{
											width: "30px",
											display: "inline",
										}}
										alt=""
									/>
								</div>
								<div style={{ clear: "both" }}></div>
								<div
									style={{
										marginTop: "20px",
										width: "400px",
									}}
								>
									<IndicatorBar
										width="100%"
										spi={spi}
										rank={rank}
										totalAreas={totalAreas}
									></IndicatorBar>
								</div>
							</div>
							<div style={{ clear: "both" }}></div>
							{mapUrl}
						</div>
						<div className="print-sc-top-part right">
							<ScoreCardLegends wh="3"></ScoreCardLegends>
						</div>
						<div style={{ clear: "both" }}></div>
					</div>
					<div style={{ width: "100%" }}>
						<div className="print-sc-all-stats-container">
							{allTables}
						</div>
					</div>
				</div>
				<div id="scorecardpage2">
					<img
						src="assets/images/AdvanceOC_logo_color_48.png"
						alt=""
					/>
					<div className="print-sc-all-stats-container">
						{allBackTables}
					</div>
				</div>
			</div>
		</>
	);
};

export default PrintableScoreCard;
