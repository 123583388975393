import React from "react";
import { Color } from "../lib/types";

export type Indicator = {
  component: string;
  indicatorName: string;
  definition: string;
  sourceName: string;
  sourceLink: string;
  year: number;
};

export type Option = {
  value: string;
  color: Color;
  componentIds: string[];
  indicators: Indicator[];
};

const Tabs = ({
  selected,
  options,
  onChange,
}: {
  selected: Option;
  options: Option[];
  onChange: (value: Option) => void;
}) => {
  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          value={selected.value}
          onChange={(e) =>
            onChange(
              options.find((v) => v.value === e.target.value) || selected
            )
          }
          className={`block w-full focus:ring-${selected.color}-500 focus:border-${selected.color}-500 border-gray-300 rounded-md`}
        >
          {options.map((option, index) => (
            <option key={index}>{option.value}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex" aria-label="Tabs">
            {options.map((option, index) => (
              <button
                key={index}
                onClick={() => onChange(option)}
                className={
                  selected.value === option.value
                    ? `w-1/3 py-4 px-1 text-center border-b-2 border-${option.color}-700 font-medium text-sm text-${option.color}-800`
                    : "w-1/3 py-4 px-1 text-center border-b-2 border-transparent font-medium text-sm text-gray-500 hover:text-gray-700 hover:border-gray-300"
                }
              >
                {option.value}
              </button>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Tabs;
