import { useEffect, useState } from "react";
import PlacesAutocomplete from "react-places-autocomplete";
import SuggestionList from "./SuggestionList";

const LocationSearchInput = ({
	value,
	onSelect,
	onChange,
}: {
	readonly value?: string | null;
	readonly onSelect: (value: string, placeId: string) => void;
	readonly onChange?: (value: string) => void;
}) => {
	const geolocation = {
		lat: 33.718154904715846,
		lng: -117.79575739254363,
	};

	const circle =
		google &&
		new google.maps.Circle({
			center: geolocation,
			radius: 35,
		});

	const [address, setAddress] = useState("");

	const handleChange = (value: string) => {
		setAddress(value);
		if (onChange) {
			onChange(value);
		}
	};

	const handleSelect = (value: string, placeId: string) => {
		setAddress(value);
		onSelect(value, placeId);
	};

	useEffect(() => {
		let addressUpdate = "";
		if (value) {
			addressUpdate = value;
		}
		setAddress(addressUpdate);
	}, [value]);

	if (circle == null) {
		return <></>;
	}

	return (
		<PlacesAutocomplete
			value={address}
			onChange={handleChange}
			onSelect={handleSelect}
			searchOptions={{
				bounds: circle.getBounds(),
				componentRestrictions: {
					country: "us",
				},
			}}
		>
			{({
				getInputProps,
				suggestions,
				getSuggestionItemProps,
				loading,
			}) => (
				<SuggestionList
					address={address}
					getInputProps={getInputProps}
					loading={loading}
					suggestions={suggestions}
					getSuggestionItemProps={getSuggestionItemProps}
				/>
			)}
		</PlacesAutocomplete>
	);
};

export default LocationSearchInput;
