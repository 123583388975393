import { AiOutlineInfoCircle } from "react-icons/ai";
import "./MapInfo.styled.css";

const MapInfo = (props: any) => {
	const { text } = props;
	return (
		<div className="infoBox">
			<AiOutlineInfoCircle />
			<p>{text}</p>
		</div>
	);
};

export default MapInfo;
