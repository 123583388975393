import { useTranslation } from "react-i18next";
import { Cut } from "../../generated/types";
import CensusTractTitle from "./CensusTractTitle";

const ScoreCardTitle = (props: any) => {
	const { t } = useTranslation();
	let scorecard = props.scorecard;
	let cardCut = props.cardCut;
	const peerGroups = scorecard.similarLabels[0] || null;

	return (
		<div>
			<h1 className="font-bold" style={{ fontSize: "1.5rem" }}>
				{cardCut === Cut.CensusTract && `${t("census_tract")} : `}
				{scorecard.name}
			</h1>
			{cardCut === Cut.CensusTract && (
				<CensusTractTitle CensusTract={scorecard.fullName} />
			)}
			<div
				className="scorecard-city-zip"
				style={{
					display: "flex",
					flexDirection: "column",
					marginTop: "10px",
				}}
			>
				{/* <span><strong>Media Household Income:</strong> $135,148</span> */}
				<span>
					<strong>{t("median_household_income")}:</strong> $
					{Math.round(
						scorecard.mhi.value?.toString()?.replace(/,/g, "")
					).toLocaleString()}
				</span>
				{peerGroups && peerGroups !== "N/A" && (
					<p style={{ marginTop: "6px" }}>
						<strong>Peer Groups: </strong>
						<span style={{ width: "auto" }}>
							{peerGroups.map((ct: any, index: number) => (
								<span key={index}>
									<span
										className="peer-group"
										onClick={() =>
											props?.handlePeerClick(ct)
										}
									>
										{ct}
									</span>
									<span style={{ color: "#56b8bb" }}>
										{index === peerGroups.length - 1
											? ""
											: ", "}
									</span>
								</span>
							))}
						</span>
					</p>
				)}
			</div>
		</div>
	);
};

export default ScoreCardTitle;
