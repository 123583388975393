/* eslint-disable */

import { useTranslation } from "react-i18next";
import { Cut, ScorecardInfo } from "../../generated/types";
import { GetPerformanceClassName } from "./PerformanceClass";
import "./ScoreCard.scss";
import "./PartialScoreCard.scss";
import ScoreCardTitle from "./ScoreCardTitle";
import { ScoreCardLegends } from "./ScoreCardLegends";
import IndicatorBar from "./IndicatorBar";
import { useEffect, useRef, useState } from "react";
import { useClickAway } from "react-use";
import { MoreInfoCache } from "./MoreInfoCache";

const StatTableRow = ({ pointData, totalAreas, onToggle, onMoreInfo }) => {
	let spi = pointData.data.valueRank ? pointData.data.valueRank.value : 1;
	let rank = pointData.data.valueRank ? pointData.data.valueRank.rank : 1;
	let arrowImageUrl = pointData.selected
		? "assets/images/arrow_up_small.png"
		: "assets/images/arrow_down_small.png";
	return (
		<tr className="padded-row">
			<td
				className="font-bold stat-name-td"
				style={{ textAlign: "left" }}
			>
				<div>{pointData.title}</div>
			</td>
			<td className="spi">{spi.toFixed(0)}</td>
			<td className="spi">{rank.toFixed(0)}</td>
			<td className="indicator">
				<div
					style={{ margin: "auto" }}
					className={GetPerformanceClassName(
						pointData.data.valueRank?.performance,
						"w-5 h-5"
					)}
				></div>
			</td>
			{/* <td className="td-layout-big compare-to" onClick={(evt)=>{onMoreInfo(evt,pointData)}}>
                <img src="assets/images/info-circle.png" className="compare-to-more-info" />
            </td> */}
			<td
				className="toggle-arrow toggle-arrow-drop"
				onClick={() => {
					onToggle(pointData);
				}}
			>
				<img src={arrowImageUrl} alt="" />
			</td>
		</tr>
	);
};

const StatTable = ({ tableData, totalAreas, onToggle, onMoreInfo }) => {
	const { t } = useTranslation();
	let spi = tableData.data.valueRank ? tableData.data.valueRank.value : 1;
	let rank = tableData.data.valueRank ? tableData.data.valueRank.rank : 1;
	let rows = [];
	for (let a = 0; a < tableData.points.length; a++) {
		let pt = tableData.points[a];
		let itemKey = "statTableRow_" + a.toString();
		rows.push(
			<StatTableRow
				key={itemKey}
				pointData={pt}
				totalAreas={totalAreas}
				onToggle={onToggle}
				onMoreInfo={onMoreInfo}
			></StatTableRow>
		);
		if (tableData.points[a].selected) {
			let r = (
				<tr className="padded-row tr-layout-big">
					<td className="stat-name-td" style={{ paddingTop: "0px" }}>
						<IndicatorBar
							totalAreas={totalAreas}
							spi={spi}
							rank={rank}
						></IndicatorBar>
					</td>
					<td colSpan={5} style={{ fontSize: "0.85rem" }}>
						{t("compared_to")} {t("last_year")}: &nbsp;&nbsp;&nbsp;
						{pt.data.compared_to_last_year === null ? (
							<strong>
								<span className="text-rameez-orange">N/A</span>
							</strong>
						) : pt.data.compared_to_last_year >= 0 ? (
							<img
								src="assets/images/arrow_top_right.png"
								style={{ width: "18px", display: "inline" }}
								alt="Arrow pointing up"
							/>
						) : (
							<img
								src="assets/images/arrow_down_right.png"
								style={{ width: "18px", display: "inline" }}
								alt="Arrow pointing down"
							/>
						)}
					</td>
				</tr>
			);
			rows.push(r);
			r = (
				<tr className="padded-row tr-layout-small">
					<td colSpan={4} style={{ paddingTop: "0px" }}>
						<IndicatorBar
							totalAreas={totalAreas}
							spi={spi}
							rank={rank}
						></IndicatorBar>
					</td>
					<td className="compare-to">
						{t("compared_to")}
						<br />
						{t("last_year")}:
					</td>
					<td className="toggle-arrow">
						{pt.data.compared_to_last_year === null ? (
							<strong>
								<span className="text-rameez-orange">N/A</span>
							</strong>
						) : pt.data.compared_to_last_year >= 0 ? (
							<img
								src="assets/images/arrow_top_right.png"
								style={{ width: "18px", display: "inline" }}
								alt="Arrow pointing up"
							/>
						) : (
							<img
								src="assets/images/arrow_down_right.png"
								style={{ width: "18px", display: "inline" }}
								alt="Arrow pointing down"
							/>
						)}
					</td>
				</tr>
			);
			rows.push(r);
		}
		if (a < tableData.points.length - 1) {
			rows.push(
				<tr key={a}>
					<td colSpan={6}>
						<div className="separator"></div>
					</td>
				</tr>
			);
		}
	}

	return (
		<div className="scorecard-stat-box">
			<div className="header">{tableData.title}</div>
			<div className="separator primary"></div>
			<table style={{ width: "100%", marginTop: "10px" }}>
				<tbody>
					<tr className="padded-row tr-layout-small">
						<td colSpan={6}>
							<div style={{ marginBottom: "10px" }}>
								<IndicatorBar
									totalAreas={totalAreas}
									spi={spi}
									rank={rank}
								></IndicatorBar>
							</div>
						</td>
					</tr>
					<tr className="tr-layout-small">
						<td colSpan={6}>
							<div className="separator"></div>
						</td>
					</tr>
					<tr className="padded-row">
						<td className="indicatorbar">
							<div className="stat-layout-big">
								<IndicatorBar
									totalAreas={totalAreas}
									spi={spi}
									rank={rank}
								></IndicatorBar>
							</div>
						</td>
						<td className="spi">
							<div style={{ display: "inline-block" }}>
								<img
									src="assets/images/spi_marker_half.png"
									style={{
										height: "10px",
										display: "inline",
										marginTop: "-2px",
									}}
									alt=""
								/>
							</div>
							<div style={{ display: "inline-block" }}>
								<b>{t("spi")}</b>
							</div>
							<br />
							<span className="text-rameez-orange">
								<strong>{spi.toFixed(0)}</strong>
							</span>
						</td>
						<td className="spi">
							<div style={{ display: "inline-block" }}>
								<img
									src="assets/images/rank_marker_half.png"
									style={{
										height: "11px",
										display: "inline",
										marginTop: "-2px",
									}}
									alt=""
								/>
							</div>
							<div style={{ display: "inline-block" }}>
								<b>{t("rank")}</b>
							</div>
							<br />
							<span className="text-rameez-orange">
								<strong>{rank.toFixed(0)}</strong>
							</span>
						</td>
						<td className="indicator">
							<div
								style={{ margin: "auto" }}
								className={GetPerformanceClassName(
									tableData.data.valueRank?.performance,
									"w-5 h-5"
								)}
							/>
						</td>
						{/* <td className="td-layout-big compare-to">
                          {t('compared_to')}<br />{t('last_year')}
                      </td> */}
						<td className="toggle-arrow">
							{tableData.data.compared_to_last_year === null ? (
								<strong>
									<span className="text-rameez-orange">
										N/A
									</span>
								</strong>
							) : tableData.data.compared_to_last_year >= 0 ? (
								<img
									src="assets/images/arrow_top_right.png"
									style={{ width: "25px" }}
									alt="Arrow pointing up"
								/>
							) : (
								<img
									src="assets/images/arrow_down_right.png"
									style={{ width: "25px" }}
									alt="Arrow pointing down"
								/>
							)}
						</td>
					</tr>
					<tr>
						<td colSpan={6}>
							<div className="separator"></div>
						</td>
					</tr>
					{rows}
				</tbody>
			</table>
		</div>
	);
};

const PartialScoreCard = ({
	scorecard,
	cardCut,
	onClick,
	totalAreas,
	handlePeerClick,
}) => {
	const moreInfoRef = useRef(null);
	const [allDataPoints, setDataPoints] = useState([]);
	const [moreInfoData, setMoreInfoData] = useState(null);
	const [lastMoreInfo, setLastMoreInfo] = useState(null);
	const { t } = useTranslation();
	useClickAway(moreInfoRef, (evt) => {
		closeMoreInfo();
	});
	useEffect(() => {
		if (allDataPoints.length === 0) {
			let tableData: any = {
				title: t("basic_human_needs"),
				title_key: "basic_human_needs",
				data: scorecard.bhn,
				points: [
					{
						title: t("NBMData"),
						title_key: "NBMData",
						data: scorecard.bhn.nbm,
						mi_key: "nbm",
						selected: false,
					},
					{
						title: t("WSData"),
						title_key: "WSData",
						data: scorecard.bhn.ws,
						mi_key: "ws",
						selected: false,
					},
					{
						title: t("HousingData"),
						title_key: "HousingData",
						data: scorecard.bhn.housing,
						mi_key: "housing",
						selected: false,
					},
					{
						title: t("PSData"),
						title_key: "PSData",
						data: scorecard.bhn.ps,
						mi_key: "ps",
						selected: false,
					},
				],
			};
			allDataPoints.push(tableData);
			tableData = {
				title: t("foundations_of_wellbeing"),
				title_key: "foundations_of_wellbeing",
				data: scorecard.wf,
				points: [
					{
						title: t("HWData"),
						title_key: "HWData",
						data: scorecard.wf.hw,
						mi_key: "hw",
						selected: false,
					},
					{
						title: t("ABKData"),
						title_key: "ABKData",
						data: scorecard.wf.abk,
						mi_key: "abk",
						selected: false,
					},
					{
						title: t("AICData"),
						title_key: "AICData",
						data: scorecard.wf.aic,
						mi_key: "aic",
						selected: false,
					},
					{
						title: t("EQData"),
						title_key: "EQData",
						data: scorecard.wf.eq,
						mi_key: "eq",
						selected: false,
					},
				],
			};
			allDataPoints.push(tableData);
			tableData = {
				title: t("opportunity"),
				title_key: "PRData",
				data: scorecard.opportunity,
				points: [
					{
						title: t("PRData"),
						title_key: "PRData",
						data: scorecard.opportunity.pr,
						mi_key: "pr",
						selected: false,
					},
					{
						title: t("PFCData"),
						title_key: "PFCData",
						data: scorecard.opportunity.pfc,
						mi_key: "pfc",
						selected: false,
					},
					{
						title: t("InclusivenessData"),
						title_key: "InclusivenessData",
						data: scorecard.opportunity.inclusiveness,
						mi_key: "inclusiveness",
						selected: false,
					},
					{
						title: t("AAEData"),
						title_key: "AAEData",
						data: scorecard.opportunity.aae,
						mi_key: "aae",
						selected: false,
					},
				],
			};
			allDataPoints.push(tableData);
			setDataPoints([...allDataPoints]);
		}
	});

	useEffect(() => {
		document.body.scrollTop = document.documentElement.scrollTop = 0;
	}, []);

	const languageChanged = (val) => {
		//has 2 levels
		for (let a = 0; a < allDataPoints.length; a++) {
			let pA = allDataPoints[a];
			pA.title = t(pA.title_key);
			for (let b = 0; b < pA.points.length; b++) {
				let pB = pA.points[b];
				pB.title = t(pB.title_key);
			}
		}
		setDataPoints([...allDataPoints]);
	};

	const onPointToggle = (point) => {
		point.selected = !point.selected;
		setDataPoints([...allDataPoints]);
	};

	const showMoreInfo = (evt, dp) => {
		let elem = moreInfoRef.current;
		elem.style.top = "50%";
		elem.style.left = "50%";
		elem.style.transform = "translate(-50%,-50%)";
		elem.style.display = "block";
		let mi = MoreInfoCache[dp.mi_key];
		if (mi) {
			setMoreInfoData({ point: dp, mi: mi });
			setLastMoreInfo(evt.target);
		} else {
			setMoreInfoData({
				point: dp,
				mi: { indicatorMeasure: "", indicatorConnection: "" },
			});
			setLastMoreInfo(evt.target);
		}
	};

	const closeMoreInfo = () => {
		// console.log("closeMoreInfo");
		let elem = moreInfoRef.current;
		elem.style.display = "none";
		setMoreInfoData(null);
		if (lastMoreInfo != null) {
			//lastMoreInfo.className="stat-table-more-info";
		}
		setLastMoreInfo(null);
	};

	const allTables = [];

	for (let a = 0; a < allDataPoints.length; a++) {
		let tableData = allDataPoints[a];
		let key = "partialTable" + a.toString();
		allTables.push(
			<StatTable
				key={key}
				tableData={tableData}
				totalAreas={totalAreas}
				onToggle={onPointToggle}
				onMoreInfo={showMoreInfo}
			></StatTable>
		);
	}

	return (
		<div>
			{/* Rameez new layout start */}
			<div className="partial-sc-top-container">
				<div className="partial-sc-top-part left">
					<div style={{ paddingLeft: "15px", paddingRight: "15px" }}>
						<ScoreCardTitle
							scorecard={scorecard}
							cardCut={cardCut}
							handlePeerClick={handlePeerClick}
						></ScoreCardTitle>
					</div>
					<hr style={{ width: "100%", marginTop: "15px" }}></hr>
					<div style={{ marginTop: "20px", marginBottom: "10px" }}>
						<div
							style={{
								float: "left",
								width: "50%",
								paddingLeft: "0px",
								borderRightWidth: "1px",
								borderRightStyle: "solid",
								borderRightColor: "rgba(0,0,0,0.15)",
							}}
						>
							<div
								className="spi-top-value"
								style={{ textAlign: "center" }}
							>
								<div style={{ display: "inline-block" }}>
									<img
										src="assets/images/spi_marker_half.png"
										style={{
											height: "10px",
											display: "inline",
											marginTop: "-3px",
										}}
										alt=""
									/>
								</div>
								<div
									className="text-rameez-orange font-bold"
									style={{
										display: "inline-block",
										marginLeft: "2px",
									}}
								>
									SPI
								</div>
							</div>
							<div
								className="font-bold spi-top-value"
								style={{ textAlign: "center" }}
							>
								{scorecard.spi.value.toFixed(0)} / 100
							</div>
						</div>
						<div
							style={{
								float: "left",
								width: "50%",
								paddingLeft: "0px",
							}}
						>
							<div
								className="spi-top-value"
								style={{ textAlign: "center" }}
							>
								<div style={{ display: "inline-block" }}>
									<img
										src="assets/images/rank_marker_half.png"
										style={{
											height: "10px",
											display: "inline",
											marginTop: "-3px",
										}}
										alt=""
									/>
								</div>
								<div
									className="text-rameez-orange font-bold"
									style={{
										display: "inline-block",
										marginLeft: "2px",
									}}
								>
									{t("rank").toUpperCase()}
								</div>
							</div>
							<div
								className="font-bold spi-top-value"
								style={{ textAlign: "center" }}
							>
								{scorecard.spi.rank} / {totalAreas}
							</div>
						</div>
						<div style={{ clear: "both" }}></div>
					</div>
				</div>
				<div className="partial-sc-top-part right">
					<ScoreCardLegends wh="3.5"></ScoreCardLegends>
					<div>
						{cardCut === "CENSUS_TRACT" && (
							<a
								id="see-scorecard-bot"
								className="btn-dawn-spi dawn-pull-btn-right"
								onClick={onClick}
								style={{
									display: "block",
									margin: "30px auto 0 auto",
									padding: "10px 15px !important",
								}}
							>
								{t("see_scorecard")}
							</a>
						)}
					</div>
				</div>
				<div style={{ clear: "both" }}></div>
			</div>
			<div className="partial-sc-overall-spi">
				<div className="section section-indicator">
					<IndicatorBar
						spi={scorecard.spi.value}
						rank={scorecard.spi.rank}
						totalAreas={totalAreas}
					></IndicatorBar>
				</div>
				<div className="section">
					<div
						className={GetPerformanceClassName(
							scorecard.spi.performance,
							"w-6 h-6"
						)}
					/>
				</div>
				<div className="section">
					<div className="text">
						{t("overall")} SPI {t("score")}
						<br />
						{t("compared_to")} {t("last_year")}:
					</div>
				</div>
				<div className="section">
					{scorecard.spi.compared_to_last_year === null ? (
						<strong>
							<span className="text-rameez-orange">N/A</span>
						</strong>
					) : scorecard.spi.compared_to_last_year >= 0 ? (
						<img
							src="assets/images/arrow_top_right.png"
							style={{ width: "20px" }}
							alt="Arrow pointing up"
						/>
					) : (
						<img
							src="assets/images/arrow_down_right.png"
							style={{ width: "20px" }}
							alt="Arrow pointing down"
						/>
					)}
				</div>
			</div>
			<hr style={{ width: "100%", marginTop: "0px" }}></hr>
			<div className="all-stats-container">{allTables}</div>
			{/* <div style={{ padding: "15px", paddingBottom: "20px" }}>
				<div style={{ float: "left" }}>
					<DropdownLanguages
						onChange={languageChanged}
					></DropdownLanguages>
				</div>
				<div style={{ float: "right", paddingTop: "15px" }}>
					<a
						id="see-scorecard-bot"
						className="btn-dawn-spi dawn-pull-btn-right"
						onClick={onClick}
					>
						{t("see_scorecard")}
					</a>
				</div>
				<div style={{ clear: "both" }}></div>
			</div> */}
			{/* Popover for more-info */}
			<div
				ref={moreInfoRef}
				className="more-info-popover"
				style={{ display: "none" }}
			>
				<div>
					{/* <div>
            Source: <a href="google.com" target="_blank" style={{color: "#3366ff",textDecoration: "underline"}}>CDC 500 Cities</a>, 2018
          </div> */}
					{moreInfoData && (
						<div style={{ marginTop: "0px" }}>
							<div style={{ fontWeight: "400" }}>
								{moreInfoData.point.title}
							</div>
							<div style={{ marginTop: "10px" }}>
								{moreInfoData.mi.indicatorMeasure}
							</div>
							<div style={{ marginTop: "10px" }}>
								{moreInfoData.mi.indicatorConnection}
							</div>
						</div>
					)}
				</div>
				<div style={{ marginTop: "15px", textAlign: "center" }}>
					<button
						type="button"
						onClick={closeMoreInfo}
						className="more-info-close"
					>
						Close
					</button>
				</div>
			</div>
			{/* Popover for more-info end */}
			{/* Rameez new layout end */}
		</div>
	);
};

export default PartialScoreCard;
