import React from "react";
import Paper from "./Paper";
import Button from "./Button";

const PageMethodology = () => {
	return (
		<Paper>
			<h1 className="text-2xl font-semibold text-burning-orange-800 border-b border-burning-orange-500 pb-4 mb-4">
				Methodology
			</h1>

			<div className="text-xl font-semibold text-burning-orange-700 mb-1">
				A tool that improves over time
			</div>
			<div className="text-tundora-500 mb-2">
				The Social Progress Index embodies a large body of research on
				moving “beyond GDP” and has identified the social and
				environmental elements of the performance of communities. We
				consider social progress in a systematic and comprehensive way,
				with a framework that comprises three elements: dimensions,
				components, and indicators.
			</div>

			<img className="mb-8" src="/ca-framework.png" alt="framework" />

			<div className="text-xl font-semibold text-burning-orange-700 mb-1">
				Key design principles
			</div>

			<div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
				<div className="bg-tundora-100 p-4">
					<div className="text-xl font-semibold text-burning-orange-700">
						Exclusively social and environmental indicators:
					</div>
					<div className="text-tundora-500">
						Our aim is to measure social progress directly, rather
						than utilize economic proxies. By excluding economic
						indicators, we can, for the first time, rigorously and
						systematically analyze the relationship between economic
						development (measured for example by GDP per capita) and
						social development. Prior efforts to move “beyond GDP”
						have commingled social and economic indicators, making
						it difficult to disentangle cause and effect.
					</div>
				</div>

				<div className="bg-tundora-100 p-4">
					<div className="text-xl font-semibold text-burning-orange-700">
						Outcomes not inputs::
					</div>
					<div className="text-tundora-500">
						Our aim is to measure the outcomes that matter to the
						lives of real people, not the inputs. For example, we
						want to measure a county’s health and wellness achieved,
						not how much effort is expended nor how much the county
						spends on healthcare.
					</div>
				</div>

				<div className="bg-tundora-100 p-4">
					<div className="text-xl font-semibold text-burning-orange-700">
						Holistic and relevant to all countries:
					</div>
					<div className="text-tundora-500">
						Our aim is to create a holistic measure of social
						progress that encompasses the many aspects of the health
						of societies. Most previous efforts have focused on the
						poorest communities, for understandable reasons. But
						knowing what constitutes a healthy society for any
						community, including those of higher-incomes, is
						indispensable in charting a course for less-prosperous
						societies to get there.
					</div>
				</div>

				<div className="bg-tundora-100 p-4">
					<div className="text-xl font-semibold text-burning-orange-700">
						Actionable:
					</div>
					<div className="text-tundora-500">
						The index aims to be a practical tool that helps leaders
						and practitioners in government, business and civil
						society to implement policies and programs that will
						drive faster social progress. To achieve that goal, we
						measure outcomes in a granular way that focuses on
						specific areas that can be implemented directly. The
						framework allows us to provide not only an aggregate
						county score and ranking, but also granular analyses of
						specific areas of strength and weakness which allow
						change-makers to identify and act upon the most pressing
						issues in their communities.
					</div>
				</div>
			</div>

			<div className="text-xl font-semibold text-burning-orange-700 mb-1">
				Conceptual framework
			</div>
			<div className="text-tundora-500 font-semibold mb-2">
				We define social progress in a comprehensive and inclusive way.
				Social progress is the capacity of a society to meet the basic
				human needs of its citizens, establish the building blocks that
				allow citizens and communities to enhance and sustain the
				quality of their lives, and create the conditions for all
				individuals to reach their full potential.
			</div>
			<div className="text-tundora-500 mb-2">
				This definition reflects an extensive and critical review and
				synthesis of both the academic and practitioner literature in a
				wide range of development topics. The Social Progress Index
				framework focuses on three distinct (though related) questions:
			</div>
			<ul className="list-inside list-decimal text-sm mb-6">
				<li>
					Does a society provide for its people’s most essential
					needs?
				</li>
				<li>
					Are the building blocks in place for individuals and
					communities to enhance and sustain wellbeing?
				</li>
				<li>
					Is there opportunity for all individuals to reach their full
					potential?
				</li>
			</ul>

			<div className="text-xl font-semibold text-burning-orange-700 mb-2">
				Dimensions of the Social Progress Index
			</div>
			<div className="text-lg font-semibold text-teal-700 mb-1">
				Basic Human Needs
			</div>
			<div className="text-tundora-500 mb-2">
				Assesses how well a country provides for its people’s essential
				needs by measuring access to nutrition and basic medical care,
				if they have access to safe drinking water, if they have access
				to adequate housing with basic utilities, and if society is safe
				and secure.
			</div>

			<div className="text-lg font-semibold text-orange-700 mb-1">
				Foundations of Wellbeing
			</div>
			<div className="text-tundora-500 mb-2">
				Assesses whether citizens have access to basic education, can
				access information and knowledge from both inside and outside
				their country, and if there are the conditions for living
				healthy lives. Foundations of Wellbeing also measures a
				country’s protection of its natural environment: air, water, and
				land, which are critical for current and future wellbeing.
			</div>

			<div className="text-lg font-semibold text-green-800 mb-1">
				Opportunity
			</div>
			<div className="text-tundora-500 mb-6">
				Measures the degree to which a country’s citizens have personal
				rights and freedoms and are able to make their own personal
				decisions as well as whether prejudices or hostilities within a
				society prohibit individuals from reaching their potential.
				Opportunity also includes the degree to which advanced forms of
				education are accessible to those in a country who wish to
				further their knowledge and skills, creating the potential for
				wide-ranging personal opportunity. One of the distinguishing
				features of the Social Progress Index framework is that it
				encompasses Opportunity, an aspect of human wellbeing that is
				often overlooked or separated in thinking about social progress
				from more foundational and material needs such as nutrition and
				healthcare.
			</div>

			<div className="text-xl font-semibold text-burning-orange-700 mb-2">
				Components
			</div>
			<div className="text-tundora-500 mb-2">
				To evaluate county performance on each of these dimensions, we
				must decompose them further into specific actionable components.
			</div>
			<div className="text-tundora-500 mb-2">
				Each component of the framework comprises between three and five
				specific outcome indicators. The included indicators are
				selected because they are measured appropriately, with a
				consistent methodology, by the same organization, and across all
				(or essentially all) of the countries in our sample.
			</div>
			<div className="text-tundora-500 mb-6">
				The overall Social Progress Index score is a simple average of
				the three dimensions. Each dimension, in turn, is the simple
				average of its four components. We discuss the reasons to weight
				each component equally, and the alternatives considered, in the
				2018
				<a
					href="https://www.socialprogress.org/static/9b25a2a187a2638e806fd39d0a7e3ab0/2018-social-progress-index-methodology.pdf"
					target="_blank"
					rel="noreferrer"
					className="text-yellow-orange-800 mx-1"
				>
					Methodology Report
				</a>
				The Methodological Note for the Social Progress Index for
				California Counties contains an overview of the adaptation of
				the Social Progress Index framework to the Californian context.
			</div>

			<div className="md:w-2/3">
				<Button
					id="download-methodology"
					onClick={() =>
						window.open(
							"https://www.socialprogress.org/CaliforniaMethodologyNote.pdf",
							"_blank"
						)
					}
				>
					Download API
				</Button>
			</div>
		</Paper>
	);
};

export default PageMethodology;
