import { Option } from "../components/Tabs"

const options: Option[] = [
  {
    value: "Basic Human Needs",
    color: "teal",
    componentIds: [
      "Nutrition and Basic Medical Care",
      "Water and Sanitation",
      "Housing",
      "Personal Safety",
    ],
    indicators: [
      {
        component: "Nutrition and Basic Medical Care",
        indicatorName: "Dental care visits (% of adults)",
        definition:
          "Percent of visits to dentist or dental clinic for adults aged >=18 in the previous year",
        sourceName: "CDC 500 Cities",
        sourceLink:
          "https://nccd.cdc.gov/500_Cities/rdPage.aspx?rdReport=DPH_500_Cities.ComparisonReport&Locations=0668000",
        year: 2018,
      },
      {
        component: "Nutrition and Basic Medical Care",
        indicatorName:
          "Households with food stamp benefits in the past 12 months (% of households)",
        definition: "Percent of households receiving food stamps",
        sourceName: "American Community Survey 5-Year Data",
        sourceLink: "https://data.census.gov/cedsci/",
        year: 2018,
      },
      {
        component: "Rental Assistance Priority Index",
        indicatorName: "Rental Assistance Priority Index",
        definition: "Rental Assistance Priority Index",
        sourceName: "American Community Survey 5-Year Data",
        sourceLink: "https://data.census.gov/cedsci/",
        year: 2018,
      },
      {
        component: "Nutrition and Basic Medical Care",
        indicatorName: "Preventative care visits (% of adults)",
        definition:
          "Percent of visits to doctor for routine checkup for adults >=18 in the previous year",
        sourceName: "CDC 500 Cities",
        sourceLink:
          "https://nccd.cdc.gov/500_Cities/rdPage.aspx?rdReport=DPH_500_Cities.ComparisonReport&Locations=0668000",
        year: 2018,
      },
      {
        component: "Nutrition and Basic Medical Care",
        indicatorName: "Supermarket access (% of pop.)",
        definition:
          "Percent of tract population that are beyond 1/2 mile from supermarket",
        sourceName: "USDA Food Access Research Atlas",
        sourceLink:
          "https://www.ers.usda.gov/data-products/food-access-research-atlas/download-the-data/",
        year: 2015,
      },
      {
        component: "Nutrition and Basic Medical Care",
        indicatorName: "Kindergarten vaccination rate (% of kindergarteners)",
        definition:
          "Percent of kindergarteners fully up-to-date on all required vaccinations; average of 2016-2017 and 2018-2019 school years.",
        sourceName: "California Health & Human Services",
        sourceLink:
          "https://data.chhs.ca.gov/dataset/school-immunizations-in-kindergarten-by-academic-year",
        year: 2019,
      },
      {
        component: "Water and Sanitation",
        indicatorName: "Safe Drinking Water Act health-based violations",
        definition: "Sum of weighted scores for drinking water contaminants.",
        sourceName: "OEHHA (CaliEnviroScreen)",
        sourceLink:
          "https://oehha.ca.gov/calenviroscreen/report/calenviroscreen-30",
        year: 2013,
      },
      {
        component: "Water and Sanitation",
        indicatorName:
          "Resource Conservation and Recovery Act hazardous waste violations",
        definition:
          "Sum of weighted scores for groundwater contamination sites within each census tract.",
        sourceName: "OEHHA (CaliEnviroScreen)",
        sourceLink:
          "https://oehha.ca.gov/calenviroscreen/report/calenviroscreen-30",
        year: 2016,
      },
      {
        component: "Water and Sanitation",
        indicatorName:
          "Businesses in compliance with health standards (% of total businesses",
        definition:
          "Percent of food facilities that have passed health inspections",
        sourceName: "Orange County Health Care Agency",
        sourceLink: "https://orangeeco.envisionconnect.com/#/pa1/search",
        year: 2019,
      },
      {
        component: "Housing",
        indicatorName: "Eviction Rate (per 100 renter homes)",
        definition:
          "Rate of evictions per 100 renter homes in each census tract",
        sourceName: "Princeton University - Eviction Lab",
        sourceLink: "https://evictionlab.org/",
        year: 2016,
      },
      {
        component: "Housing",
        indicatorName: "Housing cost burden (% of households, owners)",
        definition:
          "Percent of owned housing units with cost greater than 30% of household income.",
        sourceName: "American Community Survey 5-Year Data",
        sourceLink: "https://data.census.gov/cedsci/",
        year: 2018,
      },
      {
        component: "Housing",
        indicatorName: "Housing cost burden (% of households, renters)",
        definition:
          "Percent of rented housing units with cost greater than 30% of household income.",
        sourceName: "American Community Survey 5-Year Data",
        sourceLink: "https://data.census.gov/cedsci/",
        year: 2018,
      },
      {
        component: "Housing",
        indicatorName: "Housing overcrowding (% of households)",
        definition:
          "Percent of households with more than one occupant per room.",
        sourceName: "American Community Survey 5-Year Data",
        sourceLink: "https://data.census.gov/cedsci/",
        year: 2018,
      },
      {
        component: "Personal Safety",
        indicatorName: "Motor vehicle accident rate (per 10,000 pop)",
        definition:
          "Rate of motor vehicle accidents resulting in injury or death per 1,000 population.",
        sourceName: "UC Berkeley SafeTrec",
        sourceLink:
          "https://safetrec.berkeley.edu/tools/transportation-injury-mapping-system-tims",
        year: 2019,
      },
      {
        component: "Personal Safety",
        indicatorName: "Property crime rate (per 10,000 pop)",
        definition:
          "Property crime includes the offenses of burglary, larceny-theft, motor vehicle theft, and arson, 3-year average.",
        sourceName: "FBIs Uniform Crime Reporting (UCR) Program",
        sourceLink:
          "https://ucr.fbi.gov/crime-in-the-u.s/2016/crime-in-the-u.s.-2016/topic-pages/property-crime",
        year: 2016,
      },
      {
        component: "Personal Safety",
        indicatorName: "Violent crime rate (per 1,000 pop)",
        definition:
          "Violent crime is composed of four offenses: murder and nonnegligent manslaughter, forcible rape, robbery, and aggravated assault, 3 year-average.",
        sourceName: "FBIs Uniform Crime Reporting (UCR) Program",
        sourceLink:
          "https://ucr.fbi.gov/crime-in-the-u.s/2016/crime-in-the-u.s.-2016/topic-pages/violent-crime",
        year: 2016,
      },
    ],
  },
  {
    value: "Foundations of Wellbeing",
    color: "orange",
    componentIds: [
      "Access to Basic Knowledge",
      "Access to Info and Comms",
      "Health and Wellness",
      "Environmental Quality",
    ],
    indicators: [
      {
        component: "Access to Basic Knowledge",
        indicatorName:
          "Third grade language arts proficiency (% of 3rd graders)",
        definition:
          "Percent of third graders meeting or exceeding CAASPP standards in Language Arts.",
        sourceName: "California Department of Education",
        sourceLink: "https://caaspp.cde.ca.gov/sb2018/ResearchFileListCAA",
        year: 2019,
      },
      {
        component: "Access to Basic Knowledge",
        indicatorName: "Eighth grade math proficiency (% of 8th graders)",
        definition:
          "Percent of eighth graders meeting or exceeding CAASPP standards in Mathematics.",
        sourceName: "California Department of Education",
        sourceLink: "https://caaspp.cde.ca.gov/sb2018/ResearchFileListCAA",
        year: 2019,
      },
      {
        component: "Access to Basic Knowledge",
        indicatorName:
          "Population without high school diploma or equivalent (% of pop. ages 18-24 )",
        definition:
          "Percent of adults aged 20 and above with high school diploma incomplete",
        sourceName: "American Community Survey 5-Year Data",
        sourceLink: "https://data.census.gov/cedsci/",
        year: 2018,
      },
      {
        component: "Access to Basic Knowledge",
        indicatorName: "Preschool enrollment (% of 3 & 4 year olds)",
        definition: "Percent of three and four year olds enrolled in school.",
        sourceName: "American Community Survey 5-Year Data",
        sourceLink: "https://data.census.gov/cedsci/",
        year: 2018,
      },
      {
        component: "Access to Basic Knowledge",
        indicatorName:
          "Communication and general knowledge (% of children under 5)",
        definition:
          "Percent of children under 5 that are ready for school based on communication skills and general knowledge",
        sourceName: "First5 Orange County Children and Families Commission",
        sourceLink: "http://occhildrenandfamilies.com/",
        year: 2019,
      },
      {
        component: "Access to Basic Knowledge",
        indicatorName: "Social-emotional vulnerable (% of children under 5)",
        definition:
          "Percent of children under 5 that are social-emotional vulnerable",
        sourceName: "First5 Orange County Children and Families Commission",
        sourceLink: "http://occhildrenandfamilies.com/",
        year: 2019,
      },
      {
        component: "Access to Info and Comms",
        indicatorName: "Broadband subscription (% of pop.)",
        definition: "Percent of population with a broadband subscription.",
        sourceName: "American Community Survey 5-Year Data",
        sourceLink: "https://data.census.gov/cedsci/",
        year: 2018,
      },
      {
        component: "Access to Info and Comms",
        indicatorName: "Average broadband speed (Mbps)",
        definition:
          "Average maximum download speed in megabytes per second advertised by all consumer ISPs.",
        sourceName: "California Public Utilities Commission",
        sourceLink: "http://www.cpuc.ca.gov/General.aspx?id=1197",
        year: 2017,
      },
      {
        component: "Access to Info and Comms",
        indicatorName: "Cellular data subscription (% of pop.)",
        definition: "Percent of population with a cellular data subscription.",
        sourceName: "American Community Survey 5-Year Data",
        sourceLink: "https://data.census.gov/cedsci/",
        year: 2018,
      },
      {
        component: "Access to Info and Comms",
        indicatorName:
          "One or more types of computing devices present (% of households)",
        definition: "Percent of households that have a computer at home.",
        sourceName: "American Community Survey 5-Year Data",
        sourceLink: "https://data.census.gov/cedsci/",
        year: 2018,
      },
      {
        component: "Access to Info and Comms",
        indicatorName: "No internet access (% of pop.)",
        definition:
          "Percent of population with out any access to the internet.",
        sourceName: "American Community Survey 5-Year Data",
        sourceLink: "https://data.census.gov/cedsci/",
        year: 2018,
      },
      {
        component: "Health and Wellness",
        indicatorName: "Cancer prevalence (% of adults)",
        definition:
          "Percent of adults aged 20 and above with diagnosed cancer.",
        sourceName: "CDC 500 Cities",
        sourceLink:
          "https://nccd.cdc.gov/500_Cities/rdPage.aspx?rdReport=DPH_500_Cities.ComparisonReport&Locations=0668000",
        year: 2018,
      },
      {
        component: "Health and Wellness",
        indicatorName: "Diabetes prevalence (% of adults)",
        definition:
          "Percent of adults aged 20 and above with diagnosed diabetes.",
        sourceName: "CDC 500 Cities",
        sourceLink:
          "https://nccd.cdc.gov/500_Cities/rdPage.aspx?rdReport=DPH_500_Cities.ComparisonReport&Locations=0668000",
        year: 2018,
      },
      {
        component: "Health and Wellness",
        indicatorName: "Poor mental health days",
        definition:
          "The average number of days a tract’s adult respondents report that their mental health was not good, based on the survey question: “Thinking about your mental health, which includes stress, depression, and problems with emotions, for how many days during the past 30 days was your mental health not good?”.",
        sourceName: "CDC 500 Cities",
        sourceLink:
          "https://nccd.cdc.gov/500_Cities/rdPage.aspx?rdReport=DPH_500_Cities.ComparisonReport&Locations=0668000",
        year: 2018,
      },
      {
        component: "Health and Wellness",
        indicatorName: "Obesity prevalence (% of adults)",
        definition: "Percent of adults that report a BMI of 30 or more.",
        sourceName: "CDC 500 Cities",
        sourceLink:
          "https://nccd.cdc.gov/500_Cities/rdPage.aspx?rdReport=DPH_500_Cities.ComparisonReport&Locations=0668000",
        year: 2018,
      },
      {
        component: "Health and Wellness",
        indicatorName:
          "Vulnerable or at risk in physical health (% of children under 5)",
        definition:
          "Percent of children under 5 that are vulnerable or at risk in regard to physical health",
        sourceName: "First5 Orange County Children and Families Commission",
        sourceLink: "http://occhildrenandfamilies.com/",
        year: 2019,
      },
      {
        component: "Environmental Quality",
        indicatorName: "Carbon Footprint (metric tons CO<sub>2</sub>)",
        definition:
          "Average total household CO<sub>2</sub> emissions (including electricity, housing, food, and transportation), in metric tons.",
        sourceName: "UC Berkeley CoolClimate Network",
        sourceLink: "https://coolclimate.berkeley.edu/data",
        year: 2014,
      },
      {
        component: "Environmental Quality",
        indicatorName: "Nitrogen dioxide average annual concentration (ppb)",
        definition:
          "Average annual nitrogen dioxide concentration in parts per billion, modeled to census tract level from air quality monitoring station data.",
        sourceName: "Environmental Protection Agency",
        sourceLink:
          "https://edg.epa.gov/metadata/catalog/search/resource/details.page?uuid=%7B251AFDD9-23A7-4068-9B27-A3048A7E6012%7D",
        year: 2015,
      },
      {
        component: "Environmental Quality",
        indicatorName: "Ozone average 8-hour concentration (ppm)",
        definition:
          "Mean of summer months (May - October) of the daily maximum 8-hour ozone concentration (ppb). This measurement is used to represent short-term ozone health impacts.",
        sourceName: "OEHHA (CaliEnviroScreen)",
        sourceLink:
          "https://oehha.ca.gov/calenviroscreen/report/calenviroscreen-30",
        year: 2014,
      },
      {
        component: "Environmental Quality",
        indicatorName:
          "PM2.5 average annual concentration (&mu;g/m<sub>3</sub>)",
        definition:
          "Average concentration of fine particulate matter, or PM2.5, in the air.",
        sourceName: "OEHHA (CaliEnviroScreen)",
        sourceLink:
          "https://oehha.ca.gov/calenviroscreen/report/calenviroscreen-30",
        year: 2014,
      },
      {
        component: "Environmental Quality",
        indicatorName: "Wildfire hazard potential",
        definition: "Index of wildfire potential and social vulnerability",
        sourceName: "US Forest Service & American Community Survey",
        sourceLink:
          "https://journals.plos.org/plosone/article/metrics?id=10.1371/journal.pone.0205825",
        year: 2018,
      },
    ],
  },
  {
    value: "Opportunity",
    color: "green",
    componentIds: [
      "Personal Rights",
      "Personal Freedom and Choice",
      "Inclusiveness",
      "Access to Advanced Education",
    ],
    indicators: [
      {
        component: "Personal Rights",
        indicatorName: "Home ownership (% of households)",
        definition: "Percent of households that are owner-occupied.",
        sourceName: "American Community Survey 5-Year Data",
        sourceLink: "https://data.census.gov/cedsci/",
        year: 2018,
      },
      {
        component: "Personal Rights",
        indicatorName: "Voter registration rate (% of voting eligible pop.)",
        definition:
          "Percent of total voter registrations divided by voting eligible population in the 2018 Midterm Elections.",
        sourceName: "California Secretary of State",
        sourceLink:
          "https://www.sos.ca.gov/elections/prior-elections/statewide-election-results/general-election-november-8-2016/statement-vote/",
        year: 2018,
      },
      {
        component: "Personal Rights",
        indicatorName: "Voter turnout (% of voting eligible pop.)",
        definition:
          "Percent of total number of voters divided by voting eligible population in the 2018 Midterm Elections.",
        sourceName: "California Secretary of State",
        sourceLink:
          "https://www.sos.ca.gov/elections/prior-elections/statewide-election-results/general-election-november-8-2016/statement-vote/",
        year: 2018,
      },
      {
        component: "Personal Freedom and Choice",
        indicatorName: "Child care deserts",
        definition:
          "Ratio of population under 5 to total licensed child care capacity.",
        sourceName: "California Department of Social Services",
        sourceLink: "https://secure.dss.ca.gov/CareFacilitySearch/DownloadData",
        year: 2018,
      },
      {
        component: "Personal Freedom and Choice",
        indicatorName: "Alternative transportation usage (% of commuters)",
        definition:
          "Percent of commuters taking public transit, biking, walking, or working remotely.",
        sourceName: "American Community Survey 5-Year Data",
        sourceLink: "https://data.census.gov/cedsci/",
        year: 2018,
      },
      {
        component: "Personal Freedom and Choice",
        indicatorName: "Disconnected youth (% of 16-19 year olds)",
        definition:
          "Percent of teens and young adults ages 16-19 who are neither working nor in school.",
        sourceName: "American Community Survey 5-Year Data",
        sourceLink: "https://data.census.gov/cedsci/",
        year: 2018,
      },
      {
        component: "Personal Freedom and Choice",
        indicatorName: "Walkability index",
        definition:
          "The Walkability Index dataset characterizes every Census 2010 block group in the U.S. based on its relative walkability. Walkability depends upon characteristics of the built environment that influence the likelihood of walking being used as a mode of travel. The Walkability Index is based on the EPA's previous data product, the Smart Location Database (SLD). Block group data from the SLD was the only input into the Walkability Index, and consisted of four variables from the SLD weighted in a formula to create the new Walkability Index.",
        sourceName: "Environmental Protection Agency",
        sourceLink:
          "https://edg.epa.gov/metadata/catalog/search/resource/details.page?uuid=%7B251AFDD9-23A7-4068-9B27-A3048A7E6012%7D",
        year: 2015,
      },
      {
        component: "Inclusiveness",
        indicatorName: "Residential segregation (non-white/white)",
        definition:
          "Index of dissimilarity between white and non-white tract residents. Conceptually, dissimilarity measures the percentage of a group’s population that would have to change residence for each block to have the same percentage of that group as the tract overall. The index ranges from 0.0 (complete integration) to 1.0 (complete segregation).",
        sourceName: "American Community Survey 5-Year Data",
        sourceLink: "https://data.census.gov/cedsci/",
        year: 2018,
      },
      {
        component: "Inclusiveness",
        indicatorName: "Gender pay gap",
        definition:
          "Ratio of female to male median earnings in the past 12 months (2017 inflation-adjusted dollars)",
        sourceName: "American Community Survey 5-Year Data",
        sourceLink: "https://data.census.gov/cedsci/",
        year: 2018,
      },
      {
        component: "Inclusiveness",
        indicatorName: "Residential isolation (non-white/white)",
        definition:
          "The isolation index measures the extent to which non-white tract residents are exposed only to one another, and is computed as the non-white-weighted average of the non-white proportion in each area.",
        sourceName: "American Community Survey 5-Year Data",
        sourceLink: "https://data.census.gov/cedsci/",
        year: 2018,
      },
      {
        component: "Inclusiveness",
        indicatorName: "Linguistic isolation (% households)",
        definition:
          "Percent of households in which no one 14 and over speaks English only or speaks a language other than English at home and speaks English very well.",
        sourceName: "American Community Survey 5-Year Data",
        sourceLink: "https://data.census.gov/cedsci/",
        year: 2018,
      },
      {
        component: "Access to Advanced Education",
        indicatorName: "Associates degree holders (% of pop. age 25+)",
        definition:
          "Percent of population 25 and older whose highest degree attained is an Associate's degree.",
        sourceName: "American Community Survey 5-Year Data",
        sourceLink: "https://data.census.gov/cedsci/",
        year: 2018,
      },
      {
        component: "Access to Advanced Education",
        indicatorName: "Bachelors degree holders (% of pop. age 25+)",
        definition:
          "Percent of population 25 and older whose highest degree attained is a Bachelor's degree.",
        sourceName: "American Community Survey 5-Year Data",
        sourceLink: "https://data.census.gov/cedsci/",
        year: 2018,
      },
      {
        component: "Access to Advanced Education",
        indicatorName: "Advanced degree holders (% of pop. age 25+)",
        definition:
          "Percent of population 25 and older whose highest degree attained is a Master's, professional school degree, or Doctorate.",
        sourceName: "American Community Survey 5-Year Data",
        sourceLink: "https://data.census.gov/cedsci/",
        year: 2018,
      },
    ],
  },
]

export default options
