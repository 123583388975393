import axios from "../axios";

const getPlaceCensusTract = async (placeId) => {
	try {
		const response = await axios.get(`/map/${placeId}`);
		return response;
	} catch (err) {
		throw new Error(err.message);
	}
};

export default getPlaceCensusTract;
