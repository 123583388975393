import axios from "../axios";

const getOverlayDictionary = async () => {
	try {
		const response = await axios.get("/overlays/dictionary");
		return response;
	} catch (err) {
		throw new Error(err?.response?.data || err.message);
	}
};

export default getOverlayDictionary;
