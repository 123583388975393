import { Cut, ScorecardInfo } from "../generated/types";

const safePromise = async <T>(
	promise: Promise<T>
): Promise<readonly [T | null, Error | null]> => {
	if (!promise) {
		return [null, null];
	}

	try {
		const value = await promise;

		return [value, null];
	} catch (error) {
		return [null, error];
	}
};

const i18n = (value: string): string => {
	if (value === Cut.City) {
		return "City";
	}

	if (value === Cut.ZipCode) {
		return "Zip Code";
	}

	if (value === Cut.CensusTract) {
		return "Census Tract";
	}

	switch (value) {
		case "PSData":
			return "Personal Safety";
		case "PRData":
			return "Personal Rights";
		case "AICData":
			return "Access to Information and Communications";
		case "PFCData":
			return "Personal Freedom and Choice";
		case "HWData":
			return "Health and Wellness";
		case "AAEData":
			return "Access to Advanced Education";
		case "NBMData":
			return "Nutrition and Basic Medical Care";
		case "ABKData":
			return "Access to Basic Knowledge";
		case "InclusivenessData":
			return "Inclusiveness";
		case "WSData":
			return "Water and Sanitation";
		case "HousingData":
			return "Housing";
		case "EQData":
			return "Environmental Quality";
	}

	return "";
};

const getScores = (scorecard: ScorecardInfo, sort: number = 1) => {
	const { bhn, wf, opportunity } = scorecard;
	const { housing, nbm, ps, ws } = bhn;
	const { abk, aic, eq, hw } = wf;
	const { aae, inclusiveness, pfc, pr } = opportunity;

	return [housing, nbm, ps, ws, abk, aic, eq, hw, aae, inclusiveness, pfc, pr]
		.sort((a, b) => {
			if (a.valueRank.value < b.valueRank.value) {
				return sort;
			}

			return sort * -1;
		})
		.slice(0, 5);
};

var moneyFormatter = new Intl.NumberFormat("en-US", {
	style: "currency",
	currency: "USD",
	minimumFractionDigits: 0,
});

const ignoreCensus: { readonly [key: string]: { readonly message: string } } = {
	"6059980000": { message: "6059980000" },
	"6059021813": { message: "6059021813" },
	Notice: { message: "notice_warning" },
	UNINCORPORATED: { message: "no_data" },
};

const getCustomExtra = (id: string): [number, number] => {
	// if (id=="SAN CLEMENTE"){
	//   return [0,-0.019];
	// }
	return [0, 0];
	// switch (id) {
	//   case "ANAHEIM":
	//     return [0.025, 0.025];
	//   case "LAGUNA HILLS":
	//     return [0, 0];
	//   case "ORANGE":
	//     return [-0.01, 0];
	//   case "92867":
	//     return [-0.04, 0];
	//   case "92841":
	//     return [0, 0.015];
	//   case "92705":
	//     return [0, 0.03];
	//   case "92843":
	//     return [-0.01, 0.025];
	//   case "92688":
	//     return [-0.025, 0]
	//   default:
	//     return [-0.01, 0.015];
	// }
};

const getLatLonCenter = (coords: any[], id: string): [number, number] => {
	const bounds = new google.maps.LatLngBounds();
	const handleCoord = (coord: any[]) => {
		const latLng = new google.maps.LatLng(coord[1], coord[0]);
		bounds.extend(latLng);
	};

	coords.forEach((coord: any[]) => {
		if (coord.length === 2) {
			handleCoord(coords);
		} else {
			coord.forEach((coord2: any[]) => {
				if (coord2.length === 2) {
					handleCoord(coord2);
				} else {
					coord2.forEach((coord3) => {
						handleCoord(coord3);
					});
				}
			});
		}
	});

	const center = bounds.getCenter();
	const extra = getCustomExtra(id);
	// let area = google.maps.geometry.spherical.computeArea(bounds);
	// console.log("area: "+area);
	return [center.lng() + extra[0], center.lat() + extra[1]];
};

export {
	safePromise,
	i18n,
	getScores,
	moneyFormatter,
	ignoreCensus,
	getLatLonCenter,
};
