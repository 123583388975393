import React from "react";
import Paper from "./Paper";

const PageOverview = () => {
	return (
		<Paper>
			<h1 className="text-2xl font-semibold text-burning-orange-800 border-b border-burning-orange-500 pb-4 mb-4">
				Overview
			</h1>

			<div className="text-xl font-semibold text-burning-orange-700 mb-1">
				Tracking US decline
			</div>
			<div className="text-tundora-500 mb-2">
				<p className="mb-4">
					Since it was first launched in 2014, the Social Progress
					Index has documented the US lagging behind its global
					competitors on issues of safety, health, education,
					enviromental quality and inclusiveness. Worse, the index
					reveals no real improvement in recent years. As the world's
					largest economy, the US ranked just 25th in social progress
					on the
					<a
						href="https://www.socialprogress.org"
						target="_blank"
						rel="noreferrer"
						className="text-yellow-orange-800 mx-1"
					>
						2018 global Social Progress Index.
					</a>
					And as the
					<a
						href="https://socialprogress.blog/2018-social-progress-index-us-states/"
						target="_blank"
						rel="noreferrer"
						className="text-yellow-orange-800 mx-1"
					>
						2018 Social Progress Index for the 50 US States
					</a>
					reveals, California ranks 33rd out of 50 states, a
					remarkably poor result for the largest economy in the
					country and fifth-largest in the world.
				</p>
				<p>
					These results underline the need for a more detailed
					assessment of quality of life in California. The Social
					Progress Index for California Counties offers a distinctly
					unique way to view neighbors and regions, providing a
					roadmap for prioritization and a lens through which to
					identify scalable solutions from state peers.
				</p>
			</div>

			<img className="mb-2 w-full md:w-2/3" src="/ca-map.png" alt="map" />
			<div className="text-tundora-500 mb-8">
				Figure 1. Social Progress Map of California.
			</div>

			<div className="text-xl font-semibold text-burning-orange-700 mb-1">
				Want to learn more about the Social Progress Index?
			</div>
			<div className="text-tundora-500">
				<div>
					Check out these TED talks given by our CEO, Michael Green:
				</div>
				<ul className="list-inside list-disc text-sm pt-2 ">
					<li>
						<a
							className="text-yellow-orange-800"
							href="https://www.ted.com/talks/michael_green_what_the_social_progress_index_can_reveal_about_your_country"
							target="_blank"
							rel="noreferrer"
						>
							Want the Social Progress Index can reveal about your
							country
						</a>
					</li>
					<li>
						<a
							className="text-yellow-orange-800"
							href="https://www.ted.com/talks/michael_green_how_we_can_make_the_world_a_better_place_by_2030"
							target="_blank"
							rel="noreferrer"
						>
							How we can make the world a better place by 2030
						</a>
					</li>
					<li>
						<a
							className="text-yellow-orange-800"
							href="https://www.ted.com/talks/michael_green_the_global_goals_we_ve_made_progress_on_and_the_ones_we_haven_t"
							target="_blank"
							rel="noreferrer"
						>
							The Global Goals we've made progress on and the ones
							we haven't
						</a>
					</li>
				</ul>
			</div>
		</Paper>
	);
};

export default PageOverview;
