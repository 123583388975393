import React, { ReactNode, useContext, useState } from "react";
import { KeyValue } from "./types";

type AppState = {
  readonly geographiesCache: KeyValue | null;
  readonly setGeographiesCache: (value: KeyValue | null) => void;
};

export const AppContext = React.createContext<AppState>({
  geographiesCache: null,
  setGeographiesCache: () => {},
});

export const useAppState = (): AppState => useContext(AppContext);

const ContextProvider = ({
  children,
}: {
  readonly children: ReactNode;
}): JSX.Element => {
  const [geographiesCache, setGeographiesCache] = useState<KeyValue | null>(
    null
  );

  return (
    <AppContext.Provider
      value={{
        geographiesCache,
        setGeographiesCache,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default ContextProvider;
