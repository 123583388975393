/* eslint-disable */
import CtMapping from "../../../lib/ctmapping.json";

const ZipCodesMapping = {};
const CitiesMapping = {};

const ConstructGeoMapping = () => {
	let keys = Object.keys(CtMapping);
	let zKeys = Object.keys(ZipCodesMapping);
	let cKeys = Object.keys(CitiesMapping);
	if (zKeys.length > 0 || cKeys.length > 0) {
		return;
	}
	keys.forEach((key, idx, vals) => {
		let zips: string[] = CtMapping[key].zip;
		let cities: string[] = CtMapping[key].city;
		for (let a = 0; a < zips.length; a++) {
			let zip = zips[a];
			if (!ZipCodesMapping[zip]) {
				ZipCodesMapping[zip] = { geo_id: zip, census_tracts: [] };
			}
			ZipCodesMapping[zip].census_tracts.push(key);
		}
		for (let a = 0; a < cities.length; a++) {
			let zip = cities[a];
			if (!CitiesMapping[zip]) {
				CitiesMapping[zip] = { geo_id: zip, census_tracts: [] };
			}
			CitiesMapping[zip].census_tracts.push(key);
		}
	});
};

const GetGeoMapping = (id: string, type: string) => {
	if (type == "CITY") {
		return CitiesMapping[id];
	} else if (type == "ZIP_CODE") {
		return ZipCodesMapping[id];
	}
	return CtMapping[id];
};

export { ConstructGeoMapping, GetGeoMapping };
