import { ApolloProvider } from "@apollo/client";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import App from "./App";
import client from "./lib/client";
import ContextProvider from "./lib/context";
import reportWebVitals from "./reportWebVitals";
import "./index.scss";
import "./main.css";
import "./index.css";
import { createRoot } from "react-dom/client";

i18n.use(initReactI18next)
	.use(LanguageDetector)
	.use(HttpApi)
	.init({
		supportedLngs: ["en", "es", "ko", "vi", "zhs", "zht"],
		fallbackLng: "en",
		detection: {
			order: ["cookie", "localStorage", "sessionStorage", "htmlTag"],
			caches: ["localStorage", "cookie"],
		},
		backend: {
			loadPath: "./assets/locales/{{lng}}/translation.json",
		},
	});

const loadingMarkup = (
	<div className="py-4 text-center">
		<h2>Loading.</h2>
	</div>
);

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
	<Suspense fallback={loadingMarkup}>
		<ApolloProvider client={client}>
			<ContextProvider>
				<App />
			</ContextProvider>
		</ApolloProvider>
	</Suspense>
);

if (process.env.NODE_ENV !== "production") {
	const axe = require("@axe-core/react");
	(window as any).reactAxe = () => axe(React, ReactDOM, 1000, {});
}

reportWebVitals();
