import React from "react";

type ButtonProps = React.ButtonHTMLAttributes<any> & {
  readonly id: string;
  readonly outline?: boolean;
};

const Button = ({
  id,
  disabled,
  outline,
  ...rest
}: ButtonProps): JSX.Element => {
  return (
    <button
      id={id}
      {...rest}
      disabled={disabled}
      className={`group relative w-full flex justify-center py-2 px-4 border border-teal-700 hover:border-teal-600  text-sm leading-5 font-medium rounded-md focus:border-teal-800 focus:shadow-outline-teal transition duration-150 ease-in-out ${
        disabled ? "opacity-75 cursor-not-allowed" : ""
      } ${
        outline
          ? `font-bold text-teal-900 hover:text-teal-600`
          : `text-white  bg-teal-700 hover:bg-teal-600 active:bg-teal-800`
      }`}
    />
  );
};

export default Button;
