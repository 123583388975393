type OverlayTooltipProps = {
	overlayData: any;
};

export const OverlayTooltip = (props: OverlayTooltipProps) => {
	let overlayData = props.overlayData.content;
	let dataToDisplay = [];
	let title = overlayData.name;
	if (props.overlayData.overlayType.indexOf("hca") === 0) {
		title = overlayData.full_address;
		dataToDisplay.push(overlayData.division);
		dataToDisplay.push(overlayData.unit);
	} else {
		if (overlayData.phone && overlayData.phone !== "")
			dataToDisplay.push(overlayData.phone);
		dataToDisplay.push(overlayData.address);
	}
	let dataKey = 1;
	return (
		<table>
			<thead>
				<tr>
					<th style={{ textAlign: "left" }}>{title}</th>
				</tr>
			</thead>
			<tbody>
				{dataToDisplay.map((val) => {
					dataKey++;
					return (
						<tr key={dataKey}>
							<td>{val}</td>
						</tr>
					);
				})}
			</tbody>
		</table>
	);
};
