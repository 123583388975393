import ScoreCard from "./ScoreCard";
import { useTranslation } from "react-i18next";
import Loading from "../loading";
import { ScorecardInfo } from "../../generated/types";
import { useEffect, useState } from "react";
import { GetIdsFromJson } from "./GetMapJson";
import { ignoreCensus } from "../../lib/helpers";
import getScorecard from "../../api/baselayers/getScorecard";
import { CURRENT_YEAR } from "../../constants";
import { determineGeo } from "./utils";

const ScoreCardContainer = ({
	selectedGeo,
	partial = true,
	printed = false,
	onCloseClick,
	onClick,
	onClickSimilar,
	onScorecardLoaded,
	setScorecardView,
	selectedCutOptions,
}) => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(true);
	const [scorecard, setScorecard] = useState<ScorecardInfo>(null);
	const [totalAreas, setTotalAreas] = useState(0);

	useEffect(() => {
		const fetchScorecard = async () => {
			const geo = determineGeo(selectedCutOptions);

			try {
				const response = await getScorecard(
					CURRENT_YEAR,
					geo,
					selectedGeo.geoId
				);
				const scData = response.data;
				let ids = GetIdsFromJson(selectedGeo.cut);
				setLoading(false);
				setTotalAreas(ids.length);
				setScorecard(scData);
				if (scData != null) {
					onScorecardLoaded(scData);
				}
			} catch (err) {
				console.log(err);
			}
		};
		if (ignoreCensus[selectedGeo.geoId]) {
			setLoading(false);
		} else {
			fetchScorecard();
		}
	}, []); // eslint-disable-line

	if (loading) {
		return <Loading />;
	}

	let scorecardTag = (
		<ScoreCard
			scorecard={scorecard}
			selectedGeo={selectedGeo}
			partial={partial}
			printed={printed}
			onClick={onClick}
			onClickSimilar={onClickSimilar}
			totalAreas={totalAreas}
			setScorecardView={setScorecardView}
		/>
	);

	if (partial && !scorecard) {
		return (
			<div
				className="partial-scorecard-popup"
				style={{ height: "250px" }}
			>
				<div className="popup-header">
					<div className="title">{t("sp_summary")}</div>
					<div className="close" onClick={onCloseClick}>
						<img
							src="./assets/images/close_white.png"
							style={{ width: "20px" }}
							alt=""
						/>
					</div>
				</div>
				<div className="popup-content">{scorecardTag}</div>
			</div>
		);
	} else if (partial) {
		return (
			<div className="partial-scorecard-popup">
				<div className="popup-header">
					<div className="title">{t("sp_summary")}</div>
					<div className="close" onClick={onCloseClick}>
						<img
							src="./assets/images/close_white.png"
							style={{ width: "20px" }}
							alt=""
						/>
					</div>
				</div>
				<div className="popup-content" id="scoreCardContainer">
					{scorecardTag}
				</div>
			</div>
		);
	} else {
		return (
			<div className="full-scorecard-popup">
				<div className="popup-content" id="scoreCardContainer">
					<div className="popup-header">
						<div className="logo">
							<img
								src="assets/images/AdvanceOC_logo_color_48.png"
								alt=""
							/>
						</div>
						<div className="title">{t("sp_summary")}</div>
						<div className="close" onClick={onCloseClick}>
							<img
								src="./assets/images/close.png"
								style={{ width: "25px" }}
								alt=""
							/>
						</div>
					</div>
					{scorecardTag}
				</div>
			</div>
		);
	}
};

export default ScoreCardContainer;
