import axios from "../axios";

const getOverlayData = async (overlay) => {
	try {
		const response = await axios.get(`/overlays/${overlay}`);
		return response;
	} catch (err) {
		throw new Error(err?.response?.data || err.message);
	}
};

export default getOverlayData;
