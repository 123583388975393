import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const MoreInfoData = ({ data }) => {
	const { t } = useTranslation();

	useEffect(() => {
		const accordionHeader = document.querySelector(".accordion-header");
		const accordionContent = document.querySelector(".accordion-content");

		const toggleAccordion = () => {
			accordionContent.classList.toggle("show");
			accordionHeader.classList.toggle("open");
			document.querySelector(".arrow-icon").classList.toggle("open");
		};

		accordionHeader?.addEventListener("click", toggleAccordion);

		return () => {
			accordionHeader?.removeEventListener("click", toggleAccordion);
		};
	}, []);

	return (
		<div className="moreInfoData">
			{data?.key && <h2>{t(data.key)}</h2>}
			{data?.definition && (
				<p>
					{t("definition")}: {data.definition}
				</p>
			)}
			{data?.dataSource?.link && data?.dataSource?.text && (
				<p>
					{t("data_source")}:{" "}
					<a
						href={data.dataSource.link}
						target="_blank"
						rel="noreferrer"
					>
						{data.dataSource.text}
					</a>
				</p>
			)}
			{data?.description && (
				<p
					dangerouslySetInnerHTML={{
						__html: `<p>${t("description")}: ${
							data.description
						}</p>`,
					}}
				></p>
			)}
			{data?.additionalResources && (
				<div className="accordion">
					<input
						type="checkbox"
						id="toggle"
						className="accordion-checkbox"
					/>
					<label htmlFor="toggle" className="accordion-header">
						<p>{t("additional_resources")}</p>
						<span className="arrow-icon"></span>
					</label>
					<div
						className="accordion-content"
						dangerouslySetInnerHTML={{
							__html: data.additionalResources,
						}}
					></div>
				</div>
			)}
		</div>
	);
};

export default MoreInfoData;
