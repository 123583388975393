import React from "react";

type InputProps = React.InputHTMLAttributes<any> & {
  readonly id: string;
  readonly error?: boolean;
};

export const InputClassName =
  "shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline";

const Input = ({
  id,
  error = false,
  disabled,
  multiple,
  ...rest
}: InputProps): JSX.Element => {
  if (multiple) {
    return (
      <textarea
        rows={4}
        id={id}
        disabled={disabled}
        className={`${InputClassName} ${error ? "border-red-500" : ""} ${
          disabled ? "opacity-75 cursor-not-allowed" : ""
        }`}
        {...rest}
      />
    );
  }

  return (
    <input
      id={id}
      disabled={disabled}
      className={`${InputClassName} ${error ? "border-red-500" : ""} ${
        disabled ? "opacity-75 cursor-not-allowed" : ""
      }`}
      {...rest}
    />
  );
};

export default Input;
