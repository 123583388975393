import { AiOutlineInfoCircle } from "react-icons/ai";
import "./MapInfoBox.styled.css";

const MapInfoBox = (props: any) => {
	const { text } = props;
	return (
		<div className="mapInfoBox">
			<AiOutlineInfoCircle />
			<p>{text}</p>
		</div>
	);
};

export default MapInfoBox;
