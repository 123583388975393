const GetOverlayCategories = ()=> {
    return [
        // {id: "covid_cases",overlay_type:"covid_cases",title: "COVID CASES",title_key: "covid_cases",selectable: false,is_category: true,is_open: false},
        // {id: "covid_rate_testing_city",overlay_type:"covid_rate_testing_city",title: "COVID CASES CITY",title_key: "covid_rate_testing_city",selectable: false,is_category: true,is_open: false},
        // {id: "covid_rate_testing_zip",overlay_type:"covid_rate_testing_zip",title: "COVID CASES ZIP",title_key: "covid_rate_testing_zip",selectable: false,is_category: true,is_open: false},
        {id: "hospital_resources",overlay_type:"hospital_resources",title: "Hospital Resources",title_key: "hospital_resources",selectable: false,is_category: true,is_open: false},
        {id: "school_districts",overlay_type:"school_districts",title: "School Districts",title_key: "school_districts",selectable: false,is_category: true,is_open: false},
        //{id: "opportunity_zones",overlay_type:"opportunity_zones",title: "Opportunity Zones",title_key: "opportunity_zones",selectable: false,is_category: true,is_open: false}
    ];
}

export { GetOverlayCategories }