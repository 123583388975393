export type Maybe<T> = T | null
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  /** An RFC-3339 compliant DateTime Scalar */
  DateTime: any
  /** A JSON scalar */
  JSON: any
}

export type AaeData = {
  __typename?: "AAEData"
  aae_associates: ValueRank
  aae_bachelors: ValueRank
  aae_graduate: ValueRank
  valueRank: ValueRank
  compared_to_last_year: number
}

export type AbkData = {
  __typename?: "ABKData"
  abk_g3reading: ValueRank
  abk_g8math: ValueRank
  abk_hsincomplete: ValueRank
  abk_preschoolenroll: ValueRank
  abk_under5_comskills: ValueRank
  abk_under5_socioemotional: ValueRank
  valueRank: ValueRank
  compared_to_last_year: number
}

export type AicData = {
  __typename?: "AICData"
  aic_broadband: ValueRank
  aic_broadbandspeed: ValueRank
  aic_celldata: ValueRank
  aic_hascomputer: ValueRank
  aic_nointernet: ValueRank
  valueRank: ValueRank
  compared_to_last_year: number
}

export type AppMutation = {
  __typename?: "AppMutation"
  dummyMutation?: Maybe<Scalars["Boolean"]>
}

export type AppQuery = {
  __typename?: "AppQuery"
  scorecard: ScorecardInfo
  mapInfo: MapInfo
  censusTract?: Maybe<ScorecardInfo>
}

export type AppQueryScorecardArgs = {
  id: Scalars["ID"]
  cut: Cut
}

export type AppQueryMapInfoArgs = {
  cut: Cut
}

export type AppQueryCensusTractArgs = {
  placeId: Scalars["ID"]
}

export type AppSubscription = {
  __typename?: "AppSubscription"
  dummySubscription?: Maybe<Scalars["Boolean"]>
}

export type AreaInfo = {
  __typename?: "AreaInfo"
  name?: Maybe<Scalars["String"]>
  spi?: Maybe<Scalars["Float"]>
}

export type BhnData = {
  __typename?: "BHNData"
  housing: HousingData
  nbm: NbmData
  ps: PsData
  valueRank: ValueRank
  ws: WsData
  compared_to_last_year: number
}

export enum Cut {
  CensusTract = "CENSUS_TRACT",
  City = "CITY",
  ZipCode = "ZIP_CODE",
}

export type EqData = {
  __typename?: "EQData"
  eq_carbonfootprint: ValueRank
  eq_no2: ValueRank
  eq_ozone: ValueRank
  eq_pm25: ValueRank
  valueRank: ValueRank
  eq_wildfirehp: ValueRank
  compared_to_last_year: number
}

export type HwData = {
  __typename?: "HWData"
  hw_cancer: ValueRank
  hw_diabetes: ValueRank
  hw_mentalhealth: ValueRank
  hw_obesity: ValueRank
  hw_under5_physicalhealth: ValueRank
  valueRank: ValueRank
  compared_to_last_year: number
}

export type HousingData = {
  __typename?: "HousingData"
  s_eviction: ValueRank
  s_hburdenowner: ValueRank
  s_hburdenrenter: ValueRank
  s_overcrowded: ValueRank
  valueRank: ValueRank
  compared_to_last_year: number
  s_rapi: ValueRank
}

export type InclusivenessData = {
  __typename?: "InclusivenessData"
  i_dissimilarity: ValueRank
  i_genderpaygap: ValueRank
  i_isolation: ValueRank
  i_linguistic: ValueRank
  valueRank: ValueRank
  compared_to_last_year: number
}

export type MapInfo = {
  __typename?: "MapInfo"
  areas: Array<AreaInfo>
  url: Scalars["String"]
}

export type NbmData = {
  __typename?: "NBMData"
  nbm_dental: ValueRank
  nbm_foodstamps: ValueRank
  nbm_preventative: ValueRank
  nbm_supermarketaccess: ValueRank
  valueRank: ValueRank
  nbm_vax: ValueRank
  compared_to_last_year: number
}

export type OpportunityData = {
  __typename?: "OpportunityData"
  aae: AaeData
  inclusiveness: InclusivenessData
  pfc: PfcData
  pr: PrData
  valueRank: ValueRank
  compared_to_last_year: number
}

export type PfcData = {
  __typename?: "PFCData"
  pfc_childcaredeserts: ValueRank
  pfc_cleancommute: ValueRank
  pfc_disconnected: ValueRank
  valueRank: ValueRank
  pfc_walkindex: ValueRank
  compared_to_last_year: number
}

export type PrData = {
  __typename?: "PRData"
  pr_homeownership: ValueRank
  pr_registration: ValueRank
  pr_turnout: ValueRank
  valueRank: ValueRank
  compared_to_last_year: number
}

export type PsData = {
  __typename?: "PSData"
  ps_accidents: ValueRank
  ps_property: ValueRank
  valueRank: ValueRank
  ps_violent: ValueRank
  compared_to_last_year: number
}

export enum Performance {
  O = "O",
  E = "E",
  S = "S",
  I = "I",
  U = "U",
}

export type ScorecardInfo = {
  __typename?: "ScorecardInfo"
  mhi: ValueRank
  spi: ValueRank
  bhn: BhnData
  opportunity: OpportunityData
  wf: WfData
  similarLabels: Array<Scalars["String"]>
  url?: Maybe<Scalars["String"]>
  name: Scalars["String"]
  fullName: Scalars["String"]
}

export type ValueRank = {
  __typename?: "ValueRank"
  value: Scalars["Float"]
  rank: Scalars["Int"]
  performance: Performance
  compared_to_last_year: number
}

export type WfData = {
  __typename?: "WFData"
  abk: AbkData
  aic: AicData
  eq: EqData
  hw: HwData
  valueRank: ValueRank
  compared_to_last_year: number
}

export type WsData = {
  __typename?: "WSData"
  ws_drinkingwater: ValueRank
  ws_hazardouswaste: ValueRank
  ws_inspections: ValueRank
  ws_groundwater: ValueRank
  valueRank: ValueRank
  compared_to_last_year: number
}
