export const geoIds = [
	"6059001101",
	"6059001102",
	"6059001103",
	"6059001201",
	"6059001202",
	"6059001301",
	"6059001303",
	"6059001304",
	"6059001401",
	"6059001402",
	"6059001403",
	"6059001404",
	"6059001501",
	"6059001503",
	"6059001504",
	"6059001505",
	"6059001506",
	"6059001507",
	"6059001602",
	"6059001603",
	"6059001604",
	"6059001704",
	"6059001705",
	"6059001706",
	"6059001708",
	"6059001709",
	"6059001710",
	"6059001801",
	"6059001802",
	"6059001901",
	"6059001902",
	"6059001903",
	"6059011000",
	"6059011101",
	"6059011102",
	"6059011200",
	"6059011300",
	"6059011401",
	"6059011402",
	"6059011403",
	"6059011502",
	"6059011503",
	"6059011504",
	"6059011601",
	"6059011602",
	"6059011707",
	"6059011708",
	"6059011709",
	"6059011710",
	"6059011711",
	"6059011712",
	"6059011714",
	"6059011715",
	"6059011716",
	"6059011717",
	"6059011718",
	"6059011720",
	"6059011721",
	"6059011722",
	"6059021802",
	"6059021807",
	"6059021809",
	"6059021810",
	"6059021812",
	"6059021814",
	"6059021816",
	"6059021817",
	"6059021820",
	"6059021821",
	"6059021822",
	"6059021823",
	"6059021824",
	"6059021825",
	"6059021826",
	"6059021827",
	"6059021828",
	"6059021829",
	"6059021830",
	"6059021831",
	"6059021832",
	"6059021903",
	"6059021905",
	"6059021912",
	"6059021913",
	"6059021914",
	"6059021915",
	"6059021916",
	"6059021917",
	"6059021918",
	"6059021919",
	"6059021920",
	"6059021921",
	"6059021922",
	"6059021923",
	"6059021924",
	"6059032002",
	"6059032003",
	"6059032011",
	"6059032012",
	"6059032013",
	"6059032014",
	"6059032015",
	"6059032020",
	"6059032022",
	"6059032027",
	"6059032028",
	"6059032029",
	"6059032030",
	"6059032031",
	"6059032032",
	"6059032033",
	"6059032034",
	"6059032035",
	"6059032036",
	"6059032037",
	"6059032038",
	"6059032039",
	"6059032040",
	"6059032041",
	"6059032042",
	"6059032043",
	"6059032044",
	"6059032045",
	"6059032046",
	"6059032047",
	"6059032048",
	"6059032049",
	"6059032050",
	"6059032051",
	"6059032053",
	"6059032054",
	"6059032055",
	"6059032057",
	"6059032058",
	"6059032059",
	"6059032061",
	"6059032062",
	"6059032063",
	"6059032064",
	"6059032065",
	"6059032066",
	"6059042106",
	"6059042107",
	"6059042108",
	"6059042109",
	"6059042111",
	"6059042112",
	"6059042113",
	"6059042114",
	"6059042115",
	"6059042116",
	"6059042201",
	"6059042203",
	"6059042205",
	"6059042206",
	"6059042305",
	"6059042307",
	"6059042311",
	"6059042312",
	"6059042313",
	"6059042315",
	"6059042317",
	"6059042319",
	"6059042320",
	"6059042323",
	"6059042324",
	"6059042325",
	"6059042326",
	"6059042327",
	"6059042328",
	"6059042329",
	"6059042330",
	"6059042331",
	"6059042332",
	"6059042333",
	"6059042334",
	"6059042335",
	"6059042336",
	"6059042337",
	"6059042338",
	"6059042339",
	"6059042340",
	"6059042341",
	"6059052408",
	"6059052410",
	"6059052411",
	"6059052415",
	"6059052416",
	"6059052417",
	"6059052419",
	"6059052421",
	"6059052422",
	"6059052423",
	"6059052424",
	"6059052425",
	"6059052427",
	"6059052428",
	"6059052429",
	"6059052430",
	"6059052431",
	"6059052432",
	"6059052433",
	"6059052434",
	"6059052435",
	"6059052436",
	"6059052437",
	"6059052438",
	"6059052439",
	"6059052502",
	"6059052505",
	"6059052506",
	"6059052511",
	"6059052513",
	"6059052514",
	"6059052518",
	"6059052519",
	"6059052520",
	"6059052521",
	"6059052522",
	"6059052523",
	"6059052524",
	"6059052526",
	"6059052527",
	"6059052528",
	"6059052529",
	"6059052530",
	"6059052531",
	"6059052532",
	"6059052533",
	"6059052534",
	"6059052535",
	"6059062605",
	"6059062610",
	"6059062611",
	"6059062614",
	"6059062619",
	"6059062620",
	"6059062622",
	"6059062625",
	"6059062626",
	"6059062627",
	"6059062628",
	"6059062629",
	"6059062630",
	"6059062631",
	"6059062632",
	"6059062633",
	"6059062634",
	"6059062635",
	"6059062636",
	"6059062637",
	"6059062638",
	"6059062639",
	"6059062640",
	"6059062641",
	"6059062642",
	"6059062643",
	"6059062645",
	"6059062646",
	"6059062647",
	"6059062648",
	"6059062649",
	"6059062650",
	"6059062651",
	"6059062652",
	"6059062653",
	"6059062654",
	"6059062655",
	"6059062656",
	"6059062657",
	"6059062658",
	"6059062701",
	"6059062702",
	"6059062800",
	"6059062900",
	"6059063004",
	"6059063005",
	"6059063006",
	"6059063007",
	"6059063008",
	"6059063009",
	"6059063010",
	"6059063101",
	"6059063102",
	"6059063103",
	"6059063201",
	"6059063202",
	"6059063301",
	"6059063302",
	"6059063400",
	"6059063500",
	"6059063601",
	"6059063603",
	"6059063604",
	"6059063605",
	"6059063701",
	"6059063702",
	"6059063802",
	"6059063803",
	"6059063805",
	"6059063806",
	"6059063807",
	"6059063808",
	"6059063902",
	"6059063903",
	"6059063904",
	"6059063905",
	"6059063906",
	"6059063908",
	"6059063909",
	"6059063910",
	"6059074003",
	"6059074004",
	"6059074005",
	"6059074006",
	"6059074102",
	"6059074103",
	"6059074106",
	"6059074107",
	"6059074108",
	"6059074109",
	"6059074110",
	"6059074111",
	"6059074200",
	"6059074300",
	"6059074403",
	"6059074405",
	"6059074406",
	"6059074407",
	"6059074408",
	"6059074501",
	"6059074502",
	"6059074601",
	"6059074602",
	"6059074701",
	"6059074702",
	"6059074801",
	"6059074802",
	"6059074803",
	"6059074805",
	"6059074806",
	"6059074901",
	"6059074902",
	"6059075002",
	"6059075003",
	"6059075004",
	"6059075100",
	"6059075201",
	"6059075202",
	"6059075301",
	"6059075302",
	"6059075303",
	"6059075401",
	"6059075403",
	"6059075404",
	"6059075405",
	"6059075504",
	"6059075505",
	"6059075506",
	"6059075507",
	"6059075512",
	"6059075513",
	"6059075514",
	"6059075516",
	"6059075517",
	"6059075518",
	"6059075603",
	"6059075604",
	"6059075605",
	"6059075606",
	"6059075607",
	"6059075701",
	"6059075702",
	"6059075703",
	"6059075805",
	"6059075806",
	"6059075807",
	"6059075808",
	"6059075809",
	"6059075810",
	"6059075811",
	"6059075812",
	"6059075813",
	"6059075814",
	"6059075815",
	"6059075816",
	"6059075901",
	"6059075902",
	"6059076001",
	"6059076002",
	"6059076102",
	"6059076103",
	"6059076104",
	"6059076105",
	"6059076201",
	"6059076202",
	"6059076204",
	"6059076205",
	"6059076206",
	"6059076208",
	"6059086301",
	"6059086303",
	"6059086304",
	"6059086305",
	"6059086306",
	"6059086402",
	"6059086404",
	"6059086405",
	"6059086406",
	"6059086407",
	"6059086501",
	"6059086502",
	"6059086601",
	"6059086602",
	"6059086701",
	"6059086702",
	"6059086801",
	"6059086802",
	"6059086803",
	"6059086901",
	"6059086902",
	"6059086903",
	"6059087001",
	"6059087002",
	"6059087101",
	"6059087102",
	"6059087103",
	"6059087105",
	"6059087106",
	"6059087200",
	"6059087301",
	"6059087302",
	"6059087401",
	"6059087403",
	"6059087404",
	"6059087405",
	"6059087503",
	"6059087504",
	"6059087505",
	"6059087601",
	"6059087602",
	"6059087701",
	"6059087703",
	"6059087704",
	"6059087801",
	"6059087802",
	"6059087803",
	"6059087805",
	"6059087806",
	"6059087901",
	"6059087902",
	"6059088001",
	"6059088002",
	"6059088101",
	"6059088104",
	"6059088105",
	"6059088106",
	"6059088107",
	"6059088201",
	"6059088202",
	"6059088203",
	"6059088301",
	"6059088302",
	"6059088401",
	"6059088402",
	"6059088403",
	"6059088501",
	"6059088502",
	"6059088601",
	"6059088602",
	"6059088701",
	"6059088702",
	"6059088801",
	"6059088802",
	"6059088901",
	"6059088902",
	"6059088903",
	"6059088904",
	"6059088905",
	"6059089001",
	"6059089003",
	"6059089004",
	"6059089102",
	"6059089104",
	"6059089105",
	"6059089106",
	"6059089107",
	"6059099202",
	"6059099203",
	"6059099204",
	"6059099212",
	"6059099214",
	"6059099215",
	"6059099216",
	"6059099217",
	"6059099220",
	"6059099222",
	"6059099223",
	"6059099224",
	"6059099225",
	"6059099226",
	"6059099227",
	"6059099229",
	"6059099230",
	"6059099231",
	"6059099232",
	"6059099233",
	"6059099234",
	"6059099235",
	"6059099237",
	"6059099238",
	"6059099239",
	"6059099240",
	"6059099241",
	"6059099242",
	"6059099243",
	"6059099244",
	"6059099245",
	"6059099246",
	"6059099247",
	"6059099248",
	"6059099249",
	"6059099250",
	"6059099251",
	"6059099305",
	"6059099306",
	"6059099307",
	"6059099308",
	"6059099309",
	"6059099310",
	"6059099311",
	"6059099402",
	"6059099404",
	"6059099405",
	"6059099406",
	"6059099407",
	"6059099408",
	"6059099410",
	"6059099411",
	"6059099412",
	"6059099415",
	"6059099416",
	"6059099417",
	"6059099418",
	"6059099419",
	"6059099502",
	"6059099504",
	"6059099506",
	"6059099508",
	"6059099509",
	"6059099510",
	"6059099511",
	"6059099512",
	"6059099513",
	"6059099514",
	"6059099601",
	"6059099602",
	"6059099603",
	"6059099604",
	"6059099605",
	"6059099701",
	"6059099702",
	"6059099703",
	"6059099801",
	"6059099802",
	"6059099803",
	"6059099902",
	"6059099903",
	"6059099904",
	"6059099905",
	"6059099906",
	"6059110001",
	"6059110003",
	"6059110004",
	"6059110005",
	"6059110006",
	"6059110007",
	"6059110008",
	"6059110010",
	"6059110011",
	"6059110012",
	"6059110014",
	"6059110015",
	"6059110102",
	"6059110104",
	"6059110106",
	"6059110108",
	"6059110109",
	"6059110110",
	"6059110111",
	"6059110113",
	"6059110114",
	"6059110115",
	"6059110116",
	"6059110117",
	"6059110118",
	"6059110201",
	"6059110202",
	"6059110203",
	"6059110301",
	"6059110302",
	"6059110303",
	"6059110304",
	"6059110401",
	"6059110402",
	"6059110500",
	"6059110603",
	"6059110604",
	"6059110605",
	"6059110606",
	"6059110607",
	"6059990100",
];
