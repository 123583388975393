/* eslint-disable */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Dropdown from "./Dropdown";
import { GetOverlayCategories } from "./Helpers/Overlays";
import getOverlayDictionary from "../../api/overlays/getOverlayDictionary";

type OverlaysOptionsProps = {
	selectedOverlays: any[];
	onChange?: (arg0: any[]) => void;
};

const OverlaysOptions = (props: OverlaysOptionsProps) => {
	const [overlays, setOverlays] = useState([]);
	const { t } = useTranslation();

	useEffect(() => {
		loadOverlays();
	}, []);

	const loadOverlays = async () => {
		try {
			const response = await getOverlayDictionary();
			const layers = response.data;
			let newLayers = [];
			newLayers.push({
				id: "",
				overlay_type: "street_view",
				title: t("overlay_street_view"),
				title_key: "overlay_street_view",
				parent: "",
				geometry_type: "street",
			});
			let cats = GetOverlayCategories();
			for (let a = 0; a < cats.length; a++) {
				newLayers.push(cats[a]);
				for (let b = 0; b < layers.length; b++) {
					if (layers[b].parent == cats[a].id) {
						newLayers.push(layers[b]);
					}
				}
			}
			setOverlays(newLayers);
		} catch (err) {
			console.log(err);
		}
	};

	const onClear = () => {
		if (props.onChange) {
			props.onChange([]);
		}
	};

	const onToggle = (value) => {
		let newBls = overlays.map((val) => {
			if (val.id == value.id) {
				val.is_open = !val.is_open;
			}
			return val;
		});
		setOverlays(newBls);
		return;
	};

	const onChange = (value) => {
		if (value.id == "0") {
			console.error("value.id=0");
			return;
		}
		let selLayers = [];
		if (value.id != "0") {
			for (let a = 0; a < overlays.length; a++) {
				if (overlays[a].id == value.id) {
					selLayers.push(overlays[a]);
					break;
				}
			}
		}
		props.onChange?.call(this, selLayers);
	};

	const clearClick = () => {
		if (props.onChange) {
			props.onChange([]);
		}
	};

	const placeholderText = t("select");

	const options = [];
	options.push(overlays[0]);
	for (let a = 0; a < overlays.length; a++) {
		if (overlays[a].is_category === true) {
			let op = overlays[a];
			op.title = t(op.title_key);
			options.push(overlays[a]);
			if (overlays[a].is_open === true) {
				for (let b = a + 1; b < overlays.length; b++) {
					if (overlays[b].parent == overlays[a].id) {
						//options.push(overlays[b]);
						let opB = overlays[b];
						opB.title = t(opB.title_key);
						options.push(opB);
					}
				}
			}
		}
	}
	const hasSelected =
		props.selectedOverlays &&
		props.selectedOverlays.length > 0 &&
		props.selectedOverlays[0] != null;
	const selectedValue = hasSelected ? props.selectedOverlays[0] : null;

	return (
		<>
			<div className="select-title">{t("overlays")}</div>
			<div>
				<div className="layer-option-select">
					<Dropdown
						options={options}
						value={selectedValue}
						onChange={onChange}
						onToggle={onToggle}
						onClear={onClear}
						placeholder={placeholderText}
						autocomplete={false}
					></Dropdown>
				</div>
				{hasSelected && (
					<div className="layer-option-chips">
						{props.selectedOverlays.map((l: any, index) => {
							if (l == null) return <></>;
							return (
								<div
									key={index}
									className="layer-option-chip"
									onClick={() => {
										clearClick();
									}}
								>
									{t(l.title_key)}
								</div>
							);
						})}
					</div>
				)}
			</div>
		</>
	);
};
export default OverlaysOptions;
