import React from "react";

const Loading = (): JSX.Element => {
  return (
    // <div
    //   id="loading"
    //   className="z-50 fixed inset-0 h-full w-full flex justify-center items-center loading"
    // >
    <div
      id="loading"
      className="z-40 absolute inset-0 h-full w-full flex justify-center items-center loading"
    >
      <div className={`loader burning-orange-500 border-t-gray`} />
    </div>
  );
};

export default Loading;
