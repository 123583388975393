import Paper from "./Paper";
import Button from "./Button";

const PageResults = () => {
	return (
		<Paper>
			<h1 className="text-2xl font-semibold text-burning-orange-800 border-b border-burning-orange-500 pb-4 mb-4">
				Results
			</h1>

			<div className="text-tundora-500 mb-2">
				The Social Progress Index offers a rich framework for measuring
				the multiple dimensions of social progress, benchmarking
				success, and catalyzing greater human wellbeing. The Social
				Progress Index score is an average across scores for the three
				broad dimensions: Basic Human Needs, Foundations of Wellbeing,
				and Opportunity.
			</div>

			<div className="text-xl font-semibold text-burning-orange-700 mb-1">
				Social progress globally
			</div>
			<div className="text-tundora-500 mb-2">
				The Social Progress Index for California Counties reveals
				striking differences between counties in their overall social
				performance, and their performance across different components
				of social progress.
			</div>

			<div className="text-tundora-500 mb-2">
				This new index takes a more focused, contextualized look at what
				is driving the state's low performance and provides a roadmap
				for prioritization to adress the specific needs of communities
				across the state. The index focuses on 56 of the state's 58
				counties, using 55 indicators drawn from both state agencies and
				federal sources.
			</div>

			<div className="text-tundora-500 mb-4">
				This new index takes a more focused, contextualized look at what
				is driving the state's low performance and provides a roadmap
				for prioritization to adress the specific needs of communities
				across the state. The index focuses on 56 of the state's 58
				counties, using 55 indicators drawn from both state agencies and
				federal sources.
			</div>

			<img
				className="mb-2"
				src="/ca-average-scores.png"
				alt="average scores"
			/>
			<div className="text-tundora-500 mb-8">
				Figure 1. California state average scores.
			</div>

			<div className="text-tundora-500 mb-4">
				While there is a clear correlation between the level of economic
				development and social progress, the relationship varies
				significantly. Similar to what we have found at the global and
				national levels, each county's income group often does not
				correpond to its level of social progress.
			</div>

			<img
				className="mb-2"
				src="/ca-results-table.png"
				alt="results table"
			/>
			<div className="text-tundora-500">
				Figure 2. Social Progress Index Results Table.
			</div>

			<img src="/spi-vs-mhi.png" alt="results table" />
			<div className="text-tundora-500 mb-8">
				Figure 3. Social Progress Index vs Median Household Income.
			</div>

			<div className="text-tundora-500 mb-4">
				The Social Progress Index for California Counties offers a
				distinctly unique way to view neighbors and regions. It provides
				a roadmap for prioritization, and a lens through wich to
				identifya scalable solutions from state peers.
			</div>

			<div className="mb-4 md:w-2/3">
				<Button
					id="data-explore"
					onClick={() =>
						window.open(
							"https://www.socialprogress.org/index/california/map",
							"_blank"
						)
					}
				>
					Explore the 2019 Social Progress Index: California Counties
					data
				</Button>
			</div>

			<div className="md:w-2/3">
				<Button
					id="data-spi"
					onClick={() =>
						window.open(
							"https://www.socialprogress.org/SPI_California_Counties_Preliminary_Findings.pdf",
							"_blank"
						)
					}
				>
					2019 Social Progress Index: California Counties Preliminary
					Findings
				</Button>
			</div>
		</Paper>
	);
};

export default PageResults;
