/* eslint-disable */
import { useEffect, useState } from "react";
import Button from "./components/Button";
import Container from "./components/Container";
import Label from "./components/Label";
import PageMethodology from "./components/PageMethodology";
import PageOverview from "./components/PageOverview";
import PageResults from "./components/PageResults";
import Paper from "./components/Paper";
import Tabs, { Indicator, Option } from "./components/Tabs";
import options from "./lib/options";
import { Page } from "./lib/types";
import MapTool from "./components/Map/MapTool";
import "./App.scss";

function App(args: any) {
	const isMap = true; //useMatch("/map");
	const [selected, setSelected] = useState<Option>(options[0]);
	const [selectedComponent, setSelectedComponent] = useState<string | null>();
	const [selectedIndicator, setSelectedIndicator] = useState<Indicator>();
	const [pageLoad, setPageLoad] = useState(false);

	const indicators = selected.indicators.filter(
		(indicator) => indicator.component === selectedComponent
	);

	useEffect(() => {
		if (selected) {
			setSelectedComponent(selected.componentIds[0]);
		}
	}, [selected]);

	useEffect(() => {
		if (selectedComponent) {
			const indicator = selected.indicators.find(
				(indicator) => indicator.component === selectedComponent
			);
			setSelectedIndicator(indicator);
		}
	}, [selected.indicators, selectedComponent]);

	const [currentPage] = useState<Page>(
		isMap ? Page["Data Visualizations"] : Page["Data Definitions"]
	);

	return (
		<Container
			className={`dawn-body-font ${
				selectedComponent ? "overflow-hidden" : ""
			}`}
		>
			{/* {loadingPlace && <Loading />} */}

			{currentPage === Page.Overview && <PageOverview />}

			{currentPage === Page.Results && <PageResults />}

			{currentPage === Page.Methodology && <PageMethodology />}

			{currentPage === Page["Data Definitions"] && (
				<Paper>
					<h1 className="text-2xl font-semibold text-burning-orange-800 border-b border-burning-orange-500 pb-4 mb-4">
						Data Definitions
					</h1>

					<Tabs
						selected={selected}
						options={options}
						onChange={setSelected}
					/>

					<div className="text-center grid gap-4 grid-cols-1 md:grid-cols-3 mt-4">
						<div>
							<div className="text-center">
								<Label>Components</Label>
							</div>

							<div className="flex flex-col">
								{selected?.componentIds.map(
									(componentId, index) => (
										<button
											key={index}
											onClick={() =>
												setSelectedComponent(
													componentId
												)
											}
											className={`bg-${selected.color}-${
												selectedComponent ===
												componentId
													? "400"
													: "200"
											} p-4 mb-2 hover:bg-${
												selected.color
											}-300`}
										>
											<span className="text-sm">
												{componentId}
											</span>
										</button>
									)
								)}
							</div>
						</div>
						<div>
							<div className="text-center -ml-1">
								<Label>Indicators</Label>
							</div>

							<div
								className={`py-1 bg-${selected.color}-400 flex flex-col`}
							>
								{indicators.map((indicator, index) => (
									<button
										key={index}
										onClick={() =>
											setSelectedIndicator(indicator)
										}
										className={`${
											selectedIndicator?.indicatorName ===
											indicator.indicatorName
												? "bg-white"
												: `bg-${selected.color}-200`
										} p-4 mx-4 my-2 hover:bg-gray-100`}
									>
										<span
											className="text-sm"
											dangerouslySetInnerHTML={{
												__html: indicator.indicatorName,
											}}
										/>
									</button>
								))}
							</div>
						</div>
						<div className="text-left bg-gray-100 text-sm">
							<div className="text-center -ml-1">
								<Label>Definition</Label>
							</div>

							{selectedIndicator && (
								<div className="p-4">
									<div
										className="font-bold mb-4"
										dangerouslySetInnerHTML={{
											__html: selectedIndicator.indicatorName,
										}}
									/>
									<div
										className="mb-4"
										dangerouslySetInnerHTML={{
											__html: selectedIndicator.definition,
										}}
									/>

									<div className="mb-4">
										Source:{" "}
										<a
											className={`text-${selected.color}-800`}
											href={selectedIndicator.sourceLink}
											target="_blank"
											rel="noreferrer"
										>
											{selectedIndicator.sourceName}
										</a>
									</div>
									<div>Year: {selectedIndicator.year}</div>
								</div>
							)}
						</div>
					</div>
				</Paper>
			)}

			{currentPage === Page["Data Visualizations"] && <MapTool></MapTool>}

			{currentPage === Page["Download Data"] && (
				<Paper>
					<h1 className="text-2xl font-semibold text-burning-orange-800 border-b border-burning-orange-500 pb-4 mb-4">
						Download Data
					</h1>

					<div className="mb-4 md:w-2/3">
						<Button id="download-csv" disabled>
							Download CSV
						</Button>
					</div>

					<div className="md:w-2/3">
						<Button
							id="download-api"
							onClick={() =>
								window.open(
									"https://ocmap-qa.s3.amazonaws.com/scorecards/API_Reference.pdf",
									"_blank"
								)
							}
						>
							Download API
						</Button>
					</div>
				</Paper>
			)}
			{/* <p
				style={{
					position: "absolute",
					left: "50%",
					bottom: "1rem",
				}}
			>
				AOC Map v1.2.1
			</p> */}
		</Container>
	);
}

export default App;
