import { ReactNode } from "react";

export type ContainerProps = {
  readonly children: ReactNode;
};

export type Color = "black" | "white" | "gray" | "teal" | "orange" | "green";

export enum Page {
  "Overview" = "Overview",
  "Results" = "Results",
  "Methodology" = "Methodology",
  "Data Definitions" = "Data Definitions",
  "Data Visualizations" = "Data Visualizations",
  "Download Data" = "Download Data",
  "Data Visualizations Stand-Alone" = "Data Visualizations Stand-Alone"
}

export type KeyValue = { readonly [key: string]: any };
