import axios from "../axios";

const getBaselayerData = async (year, geo, layerType, contentKey) => {
	try {
		const response = await axios.get(
			`/baselayers/${year}/${geo}/${layerType}/${contentKey}`
		);
		return response;
	} catch (err) {
		throw new Error(err?.response?.data || err.message);
	}
};

export default getBaselayerData;
