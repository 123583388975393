const PinScorecard = ({ data }) => {
	return (
		<div className="pin-scorecard-container">
			<h3>{data.name}</h3>
			<p>{data.phone}</p>
			<p>{data.address}</p>
		</div>
	);
};

export default PinScorecard;
