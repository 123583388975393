/* eslint-disable */

import { useEffect, useState } from "react";

const normalize = (
	minA: number,
	maxA: number,
	minB: number,
	maxB: number,
	val: number
) => {
	const normalizedValue = (val - minA) / (maxA - minA);
	const scaledValue = normalizedValue * (maxB - minB) + minB;
	return Math.max(minB, Math.min(maxB, scaledValue));
};

const IndicatorBar = (props) => {
	let width = props.width ? props.width : "100%";
	const spi = props.spi ? props.spi : 1;
	const rank = props.rank ? props.rank : 1;
	const totalAreas = props.totalAreas ? props.totalAreas : 1;
	const [normalizedSpi, setNormalizedSpi] = useState(0);
	const rankPerc = normalize(0, totalAreas, 5, 95, totalAreas - rank);

	useEffect(() => {
		if (spi > 0 && spi < 1) {
			setNormalizedSpi(normalize(0, 1, 5, 95, spi));
		} else {
			setNormalizedSpi(normalize(0, 100, 5, 95, spi));
		}
	}, []);

	return (
		<div className="indicator-bar" style={{ width: width }}>
			<div className="marker" style={{ left: rankPerc + "%" }}>
				<img alt="" src="assets/images/rank_marker.png" />
			</div>
			<div
				className="marker"
				style={{ left: normalizedSpi.toFixed(0) + "%" }}
			>
				<img alt="" src="assets/images/spi_marker.png" />
			</div>
		</div>
	);
};
export default IndicatorBar;
