import React from "react";

import { ContainerProps } from "../lib/types";

type PaperProps = ContainerProps & {
  readonly className?: string;
};

const Container = ({ children, className }: PaperProps): JSX.Element => (
  // <div className={`max-w-7xl mx-auto ${className}`}>
  <div className={`${className}`}>
    {children}
  </div>
);

export default Container;
